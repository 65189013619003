import { CircularProgress, Divider, List, ListItem, Stack, Typography } from '@mui/material'
import { shouldDisplayInterest } from '@src/data/creditapp-selectors'
import { getFormattedPlan, getMerchantFees, getSelectedPlan, getTotalObligation } from '@src/data/worksheet-selectors'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { ComputePersonalLoanFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { EPaymentPlan } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApplication: CreditApplication
  amountRequested: number
  hasVariableInterest: boolean
  firstPaymentOn: string
  merchant: Merchant
  paymentPlanId: EPaymentPlan
  provinceSupportsInsurance: boolean
  computedPersonalLoanFunding: ComputePersonalLoanFundingResultDto
  isComputing: boolean
}
const WorksheetFundingSummary = ({
  creditApplication,
  amountRequested,
  hasVariableInterest,
  firstPaymentOn,
  merchant,
  paymentPlanId,
  provinceSupportsInsurance,
  computedPersonalLoanFunding,
  isComputing,
}: Props) => {
  const { t } = useTranslation()
  const totalObligation = getTotalObligation(amountRequested, computedPersonalLoanFunding)
  const selectedPlan = getSelectedPlan(merchant, paymentPlanId)
  const mustDisplayInterests = shouldDisplayInterest(creditApplication)
  const insuranceTotal =
    (computedPersonalLoanFunding?.insuranceFee ?? 0) + (computedPersonalLoanFunding?.insuranceTax ?? 0)
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">{t('worksheet.fundingSummary')} :</Typography>
      {isComputing && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!computedPersonalLoanFunding && !isComputing && <Typography>{t('worksheet.personalFundingInvalid')}</Typography>}
      {computedPersonalLoanFunding && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2">{t('worksheet.requestedAmount')} :</Typography>
            <Typography>{FormatCurrency(amountRequested)}</Typography>
          </Stack>
          {mustDisplayInterests && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  {`${t('worksheet.interest')} (${
                    hasVariableInterest
                      ? getFormattedPlan(selectedPlan)
                      : `${creditApplication?.finalCreditDecision?.interestRate}%`
                  })`}
                  :
                </Typography>
                <Typography>{FormatCurrency(computedPersonalLoanFunding?.totalInterestAmount)}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  {`${t('worksheet.financeFee')} (${computedPersonalLoanFunding?.icebergLenderAdminFeeRate}%)`}:
                </Typography>
                <Typography>{FormatCurrency(computedPersonalLoanFunding?.icebergLenderAdminFee)}</Typography>
              </Stack>

              {hasVariableInterest && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{`${t('worksheet.merchantFee')} (${selectedPlan?.merchantFeeRate}%)`} :</Typography>
                  <Typography>{getMerchantFees(selectedPlan!.merchantFeeRate, amountRequested)}</Typography>
                </Stack>
              )}

              {provinceSupportsInsurance && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('worksheet.insurance')} :</Typography>
                  <Typography>{FormatCurrency(insuranceTotal)}</Typography>
                </Stack>
              )}

              <Stack direction="row" justifyContent="space-between">
                <Typography>{t('worksheet.totalObligation')} :</Typography>
                <Typography>{FormatCurrency(totalObligation)}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography>{t('worksheet.annualPercentageRate')} :</Typography>
                <Typography> {computedPersonalLoanFunding?.effectiveRate}%</Typography>
              </Stack>
            </>
          )}

          <List
            dense
            subheader={<Typography variant="subtitle2">{t('worksheet.paymentTitle')}</Typography>}
            sx={{ pb: 0 }}
          >
            <ListItem sx={{ justifyContent: 'space-between' }} disableGutters>
              <Typography>{t('worksheet.maxPmtAmount')} :</Typography>
              <Typography>{FormatCurrency(creditApplication?.finalCreditDecision.maxPmtAmount)}</Typography>
            </ListItem>
            <Divider component="li" />

            <ListItem sx={{ justifyContent: 'space-between' }} disableGutters>
              <Typography>{t('worksheet.paymentFrequency')} :</Typography>
              <Typography>{t('worksheet.monthly')}</Typography>
            </ListItem>
            <Divider component="li" />

            <ListItem sx={{ justifyContent: 'space-between' }} disableGutters>
              <Typography>{t('worksheet.monthlyPayment')} :</Typography>
              <Typography>{FormatCurrency(computedPersonalLoanFunding?.paymentForFrequency)}</Typography>
            </ListItem>
            <Divider component="li" />

            <ListItem sx={{ justifyContent: 'space-between' }} disableGutters>
              <Typography>{t('worksheet.firstPaymentOn')} :</Typography>
              <Typography>{formatDate(firstPaymentOn)}</Typography>
            </ListItem>
          </List>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2">{t('worksheet.amountToBePaid')} :</Typography>
            <Typography>{FormatCurrency(amountRequested ?? 0)}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default WorksheetFundingSummary
