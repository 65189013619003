import { InteractionType } from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { loginRequest } from '@src/authConfig'
import { AppContext, useAppStore } from '@src/contexts/AppContext'
import { saveUserToken, signIn } from '@src/services/Authentification'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useEffect } from 'react'

type Props = {
  children: JSX.Element
}

const UserProvider = ({ children }: Props) => {
  const { instance, accounts, inProgress } = useMsal()
  useMsalAuthentication(InteractionType.Redirect, loginRequest)
  const appStore = useAppStore()

  useEffect(() => {
    if (accounts[0] && appStore.user === null) {
      instance.setActiveAccount(accounts[0])
      instance
        .acquireTokenSilent({ account: accounts[0], scopes: [] })
        .then((response) => {
          return signIn({ jwt: response.idToken })
        })
        .then((response) => {
          saveUserToken(response.jwt)
          appStore.reloadUser()
        })
        .catch(reportErrorToConsole)
    }
  })

  if (inProgress !== 'none' || !accounts.length || appStore.user === null) return null
  return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>
}

export default UserProvider
