import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'

import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import DialogCloseButton from '@src/components/common/DialogCloseButton'
import FlinksOptionBox from './FlinksOptionBox'
import ManualOptionBox from './ManualOptionBox'

type Props = {
  open: boolean
  onClose: () => void
  applicantType: EApplicantType
  creditApplicationId: string
  canSkipFlinks: boolean
  financingProgramId: EFinancingProgram
}

const BankAccountProcedureDialog = ({
  open,
  onClose,
  applicantType,
  creditApplicationId,
  canSkipFlinks,
  financingProgramId,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent dividers={false}>
        <Stack justifyContent="center" direction="row" spacing={2}>
          <FlinksOptionBox canSkipFlinks={canSkipFlinks} />
          {canSkipFlinks && (
            <ManualOptionBox
              financingProgramId={financingProgramId}
              creditApplicationId={creditApplicationId}
              applicantType={applicantType}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="right" textAlign="center" marginRight={2}>
          <Grid item xs={12} md={4}>
            <AsyncActionButton variant="contained" onClick={onClose}>
              {t('incomeVerification.bankAccountProcedure.understand')}
            </AsyncActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default BankAccountProcedureDialog
