import { yupResolver } from '@hookform/resolvers/yup'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import CreditConsentDialog from '@src/components/CreditConsentDialog'
import PageSpinner from '@src/components/PageSpinner'
import ServerErrorsAlert from '@src/components/ServerErrorsAlert'
import TabPanel from '@src/components/TabPanel'
import { useBeneficiaryTypes } from '@src/containers/ViewCreditApplicationPage/credit-hooks'
import { useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import {
  ApplicantDto,
  CreditApplication,
  DraftApplicantSchema,
  DraftCreditApplicationDto,
  DraftCreditApplicationSchema,
  FullCreditApplicationSchema,
} from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  EApplicantType,
  EBeneficiaryType,
  ECreditApplicationStatus,
  EFinancingProgram,
  EMerchantType,
  EServiceCategory,
} from '@src/types/Constants'
import translate from '@src/services/translate'
import { AxiosError } from 'axios'
import { programConfigByFinancingProgramId } from '@src/data/creditapp-selectors'
import { BusinessError, validateCreditApplicationCanBeSubmittedForReview } from '../../../data/credit-app-validations'
import ApplicantParameters from './Applicant/ApplicantComponent'
import FinancingAmountAndBeneficiary from './Applicant/components/financingAmountAndBeneficiary'
import GetCreditContentConsentText from './creditApplicationForm-selectors'

interface Props {
  merchant: Merchant | null
  creditApplication: DraftCreditApplicationDto
  onSave: (data: Partial<CreditApplication>) => void
  isPending: boolean
  isError: boolean
  serverErrors: AxiosError | null
  financingProgramId: EFinancingProgram
  lockApplicantIncomeAndExpenses?: boolean
  lockCoApplicantIncomeAndExpenses?: boolean
}

const enum DialogComponentRender {
  prequalify,
  qualify,
  submit,
}

const CreditApplicationForm = ({
  merchant,
  creditApplication,
  onSave,
  isPending,
  isError,
  serverErrors,
  financingProgramId,
  lockApplicantIncomeAndExpenses,
  lockCoApplicantIncomeAndExpenses,
}: Props) => {
  const [draftMod, setDraftMod] = React.useState(true)

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<DraftCreditApplicationDto>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: { ...creditApplication, financingProgramId },
    resolver: draftMod ? yupResolver(DraftCreditApplicationSchema) : yupResolver(FullCreditApplicationSchema),
  })

  const { t } = useTranslation()
  const navigate = useNavigate()

  const programConfig = programConfigByFinancingProgramId(financingProgramId)
  const isBanner = merchant?.merchantTypeId === EMerchantType.Banner
  const [merchantsList] = useMerchantByParentId(merchant?.id ?? '')
  const [searchParams] = useSearchParams()
  const [hasCoapplicantChecked, setCoApplicantChecked] = useState<boolean>(!!creditApplication.coApplicant)
  const [openConsentDialog, setOpenConsentDialog] = useState(false)
  const [consentChecked, setConsentChecked] = useState(false)
  const [globalErrors, setGlobalErrors] = useState<BusinessError[]>([])
  const [dialogRender, setDialagRender] = useState<DialogComponentRender>(DialogComponentRender.prequalify)

  const loanPurposeId = watch('loanPurposeId') as EServiceCategory
  const beneficiaryTypeId = Number(watch('beneficiaryTypeId')) as EBeneficiaryType
  const isVeterinaryLoanPurpose = EServiceCategory.Veterinary === loanPurposeId
  const isGoodAndServiceLoanPurpose = EServiceCategory.GoodsAndServices === loanPurposeId
  const disableOtherName = beneficiaryTypeId !== EBeneficiaryType.Other
  const showTabIndex = searchParams.get('showTabIndex')
  const tabIndex = Number(showTabIndex) ?? 0
  const editDisabled = creditApplication?.editLocked
  const stateIso = watch('applicant.currentAddress.stateIso')
  const isQualify =
    (!programConfig.useSoftHit && programConfig.useHardHit) ||
    programConfig.useAlwaysSubmit ||
    creditApplication.consentHardHit
  const isEditingApplication = creditApplication.id !== undefined

  useEffect(() => {
    if (merchant && !isBanner) {
      setValue('merchantId', merchant.id, { shouldValidate: true })
      setValue('loanPurposeId', merchant?.serviceCategory ?? '', { shouldValidate: true })
    }
    if (isVeterinaryLoanPurpose) {
      setValue('beneficiaryTypeId', EBeneficiaryType.Other, { shouldValidate: true })
      setValue('otherBeneficiaryFirstName', '', { shouldValidate: true })
    }
    if (beneficiaryTypeId === EBeneficiaryType.Applicant || beneficiaryTypeId === EBeneficiaryType.Coapplicant) {
      setValue('otherBeneficiaryFirstName', '', { shouldValidate: true })
      setValue('otherBeneficiaryLastName', '', { shouldValidate: true })
    }
    if (isGoodAndServiceLoanPurpose) {
      setValue('beneficiaryTypeId', EBeneficiaryType.Applicant, { shouldValidate: true })
    }
    if (serverErrors) {
      window.scrollTo(0, 0)
    }
  }, [
    merchant,
    isBanner,
    setValue,
    isVeterinaryLoanPurpose,
    serverErrors,
    beneficiaryTypeId,
    isGoodAndServiceLoanPurpose,
  ])

  const beneficiaryTypes = useBeneficiaryTypes(loanPurposeId)

  const handleToggleCoapplicant = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('coApplicant', DraftApplicantSchema.getDefault() as ApplicantDto)
        setValue('coApplicant.isPrimaryApplicant', false)
      } else {
        setValue('coApplicant', null)
      }
      setCoApplicantChecked(event.target.checked)
    },
    [setValue],
  )

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const { location } = window
      const updateSearch = new URLSearchParams(location.search)
      updateSearch.set('showTabIndex', encodeURIComponent(newValue))
      const newUrl = `${location.pathname}?${updateSearch.toString()}`
      navigate(newUrl, { replace: true })
    },
    [navigate],
  )

  const submit = useCallback(
    (data: CreditApplication) => {
      onSave(data)
    },
    [onSave],
  )

  const handlePrequalify = useCallback(
    async (data: CreditApplication) => {
      const isFormValid = await trigger()
      if (isFormValid) {
        const cErrors = validateCreditApplicationCanBeSubmittedForReview(data)
        setGlobalErrors(cErrors)
        if (cErrors.length <= 0) {
          data.status = ECreditApplicationStatus.Active
          data.consentSoftHit = true
          data.consentHardHit =
            dialogRender === DialogComponentRender.qualify || dialogRender === DialogComponentRender.submit
          onSave(data)
        }
      }
    },
    [trigger, dialogRender, onSave],
  )

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentChecked(event.target.checked)
  }, [])

  const handleConfirm = useCallback(
    async (data: CreditApplication) => {
      if (consentChecked) {
        setOpenConsentDialog(false)
        await handlePrequalify(data)
      }
    },
    [consentChecked, handlePrequalify],
  )

  const getDialogContent = useMemo(() => {
    switch (dialogRender) {
      case DialogComponentRender.prequalify:
        return <Typography>{t('submission.creditAgreementMessage')}</Typography>
      case DialogComponentRender.qualify:
        return <GetCreditContentConsentText financingProgram={financingProgramId} />
      default:
        return <Typography>{t('common.submitConfirmation')}</Typography>
    }
  }, [dialogRender, financingProgramId, t])

  const getDialogConfirmationButtonText = useMemo(() => {
    switch (dialogRender) {
      case DialogComponentRender.prequalify:
        return t('submission.submitPrequal')
      case DialogComponentRender.qualify:
        return t('submission.submit')
      default:
        return <Typography>{t('common.submit')}</Typography>
    }
  }, [dialogRender, t])

  return (
    <>
      <PageSpinner isLoading={isPending} withBackdrop />
      <Paper>{serverErrors && <ServerErrorsAlert serverErrors={serverErrors} />}</Paper>
      <form onSubmit={handleSubmit((data) => submit(data as CreditApplication), reportErrorToConsole)}>
        <Card>
          {(programConfig.requestedLoanAmountIsRequired || programConfig.benefyciaryIsRequired) && (
            <CardContent>
              <FinancingAmountAndBeneficiary
                isBanner={isBanner}
                merchantsList={merchantsList}
                setValue={setValue}
                errors={errors}
                merchant={merchant}
                loanPurposeId={loanPurposeId}
                register={register}
                isGoodAndServiceLoanPurpose={isGoodAndServiceLoanPurpose}
                beneficiaryTypes={beneficiaryTypes}
                isVeterinaryLoanPurpose={isVeterinaryLoanPurpose}
                disableOtherName={disableOtherName}
              />
            </CardContent>
          )}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange}>
              <Tab
                id="applicantTab"
                icon={errors.applicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.applicant')}
              />
              <Tab
                id="coApplicantTab"
                icon={errors.coApplicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.coApplicant')}
              />
            </Tabs>
          </Box>
          <input type="hidden" value=" " {...register('id')} />
          <input type="hidden" value=" " {...register('merchantId')} />
          <CardContent>
            <TabPanel value={tabIndex} index={0}>
              <ApplicantParameters
                register={register}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                errors={errors.applicant}
                applicantType={EApplicantType.Applicant}
                formControl={control}
                editDisabled={editDisabled || (lockApplicantIncomeAndExpenses ?? false)}
                prohibitedPhone={watch('coApplicant.cellPhone')}
                financingProgramId={financingProgramId}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <FormControlLabel
                control={
                  <Checkbox id="toggleCoapplicant" checked={hasCoapplicantChecked} onChange={handleToggleCoapplicant} />
                }
                label={t('common.coApplicant')}
              />
              {hasCoapplicantChecked && (
                <ApplicantParameters
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                  errors={errors?.coApplicant}
                  applicantType={EApplicantType.Coapplicant}
                  formControl={control}
                  editDisabled={editDisabled || (lockCoApplicantIncomeAndExpenses ?? false)}
                  prohibitedPhone={watch('applicant.cellPhone')}
                  financingProgramId={financingProgramId}
                />
              )}
            </TabPanel>
          </CardContent>

          {globalErrors.length > 0 && (
            <Box display="flex" justifyContent="center">
              <Alert severity="error" sx={{ width: 'fit-content' }}>
                {globalErrors.map((error) => (
                  <Typography key={error.message}>
                    {translate(error.message, { minIncome: error.params!.minIncome })}
                  </Typography>
                ))}
              </Alert>
            </Box>
          )}

          <CardActions sx={{ p: 3 }}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12} sm={4} md={3}>
                <AsyncActionButton
                  id="cancelNewApplicantion"
                  color="error"
                  onClick={() => {
                    if (!isEditingApplication) {
                      navigate('/credits')
                    } else {
                      navigate(`/credits/${creditApplication.financingProgramId}/${creditApplication.id}`)
                    }
                  }}
                >
                  {t('common.cancel')}
                </AsyncActionButton>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <AsyncActionButton
                  id="saveDraft"
                  type="submit"
                  value="draft"
                  disabled={isPending && draftMod}
                  isPending={isPending && draftMod}
                  isError={isError && draftMod}
                  onClick={() => {
                    setDraftMod(true)
                  }}
                >
                  {t('common.saveDraft')}
                </AsyncActionButton>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                {!isQualify && (
                  <AsyncActionButton
                    id="prequalify"
                    type="button"
                    variant="contained"
                    value="prequalify"
                    disabled={isPending && !draftMod}
                    color="primary"
                    isError={isError && !draftMod}
                    isPending={isPending && !draftMod}
                    onClick={() => {
                      setOpenConsentDialog(true)
                      setDraftMod(false)
                    }}
                  >
                    {t('common.prequalify')}
                  </AsyncActionButton>
                )}

                {isQualify && (
                  <AsyncActionButton
                    id="qualify"
                    type="button"
                    variant="contained"
                    value="qualify"
                    disabled={isPending && !draftMod}
                    color="warning"
                    isError={isError && !draftMod}
                    isPending={isPending && !draftMod}
                    onClick={() => {
                      if (
                        creditApplication.consentHardHit ||
                        (!programConfig.useHardHit && !programConfig.useHardHitForSpecificProvince?.includes(stateIso!))
                      )
                        setDialagRender(DialogComponentRender.submit)
                      else setDialagRender(DialogComponentRender.qualify)

                      setOpenConsentDialog(true)
                      setDraftMod(false)
                    }}
                  >
                    {t('common.submit')}
                  </AsyncActionButton>
                )}
              </Grid>
            </Grid>
          </CardActions>
          <CreditConsentDialog
            openConsentDialog={openConsentDialog}
            setOpenConsentDialog={setOpenConsentDialog}
            consentChecked={consentChecked}
            handleCheckboxChange={handleCheckboxChange}
            handleConfirm={handleSubmit((data) => handleConfirm(data as CreditApplication), reportErrorToConsole)}
            dialogContent={getDialogContent}
            buttonText={getDialogConfirmationButtonText}
            checkBoxText={dialogRender === DialogComponentRender.submit ? t('common.yes') : undefined}
          />
        </Card>
      </form>
    </>
  )
}
export default CreditApplicationForm
