import { Box, BoxProps } from '@mui/material'
import React, { memo } from 'react'

type Props = Omit<BoxProps, 'component'> & {
  label: React.ReactNode
}

const Fieldset = ({ children, label, sx, ...props }: Props) => {
  return (
    <Box component="fieldset" sx={{ borderColor: 'divider', borderStyle: 'solid', borderWidth: 1, ...sx }} {...props}>
      <legend>{label}</legend>
      {children}
    </Box>
  )
}

export default memo(Fieldset)
