import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import ResetBankAccountButton from './ResetBankAccountButton'

type Props = {
  hasIdentityDocumentsPendingUpload: boolean
  hasIdentityDocumentsPendingReview: boolean
  allIncomesConfirmed: boolean
  applicantType: EApplicantType
  creditApplicationId: string
  canEdit: boolean
  financingProgramId: EFinancingProgram
}

const NameMismatch = ({
  hasIdentityDocumentsPendingUpload,
  hasIdentityDocumentsPendingReview,
  allIncomesConfirmed,
  applicantType,
  creditApplicationId,
  canEdit,
  financingProgramId,
}: Props) => {
  const { t } = useTranslation()
  const nameMismatchUnderReview =
    (hasIdentityDocumentsPendingReview || !allIncomesConfirmed) && !hasIdentityDocumentsPendingUpload

  return (
    <div>
      <p>{t('incomeVerification.nameMismatch.bankAccountNameMismatch')}</p>
      {nameMismatchUnderReview && (
        <>
          <p>{t('incomeVerification.startVerification.reviewing')}</p>
          <p>{t('incomeVerification.startVerification.notified')}</p>
        </>
      )}
      {hasIdentityDocumentsPendingUpload && <p>{t('incomeVerification.nameMismatch.documents')}</p>}
      <p>{t('incomeVerification.nameMismatch.reset')}</p>
      {canEdit && (
        <ResetBankAccountButton
          applicantType={applicantType}
          id={creditApplicationId}
          financingProgramId={financingProgramId}
        />
      )}
    </div>
  )
}

export default NameMismatch
