import { Card, CardContent, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import SkipBankAccountRequestRequirements from './SkipBankAccountRequestRequirements'

type Props = {
  creditApplicationId: string
  applicantType: EApplicantType
  financingProgramId: EFinancingProgram
}

const ManualOptionBox = ({ creditApplicationId, applicantType, financingProgramId }: Props) => {
  const { t } = useTranslation()

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5">Option 2</Typography>
        <Divider sx={{ marginBottom: '1rem' }} />
        <Typography variant="h6" marginBottom="1rem">
          {t('incomeVerification.bankAccountProcedure.optionTwo')}
        </Typography>
        <Typography marginBottom="1rem">{t('incomeVerification.bankAccountProcedure.upload')}</Typography>
        <SkipBankAccountRequestRequirements
          financingProgramId={financingProgramId}
          creditApplicationId={creditApplicationId}
          applicantType={applicantType}
        />
      </CardContent>
    </Card>
  )
}

export default ManualOptionBox
