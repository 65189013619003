import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { GridFooter, GridFooterContainer, GridPaginationModel } from '@mui/x-data-grid'

type Props = {
  pagination: GridPaginationModel
  setPagination: (paginationn: GridPaginationModel) => void
  disabledPreview: boolean
  disabledNext: boolean
}

const Footer = ({ pagination, setPagination, disabledPreview, disabledNext }: Props) => {
  return (
    <GridFooterContainer>
      <GridFooter
        sx={{ '& .MuiTablePagination-actions, & .MuiTablePagination-displayedRows': { display: 'none' }, border: 0 }}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
          disabled={disabledPreview}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography>{pagination.page + 1}</Typography>
        <IconButton onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })} disabled={disabledNext}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
    </GridFooterContainer>
  )
}

export default Footer
