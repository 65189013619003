import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'
import DialogCloseButton from './common/DialogCloseButton'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  title: string | React.ReactNode
  children?: React.ReactNode
  open: boolean
  disabled?: boolean
}

const ActionsDialog = ({ onCancel, onConfirm, title, children, open, disabled = false }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <DialogCloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <AsyncActionButton fullWidth={false} startIcon={<CancelIcon />} onClick={onCancel} color="error">
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton
          fullWidth={false}
          disabled={disabled}
          onClick={onConfirm}
          variant="contained"
          color="primary"
          startIcon={<DoneIcon />}
        >
          {t('common.confirm')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ActionsDialog)
