import { forwardRef } from 'react'
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkComponent = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  ({ href, ...other }, ref) => {
    // Map href (MUI) -> to (react-router)
    return <Link ref={ref} to={href} {...other} />
  },
)

export default LinkComponent
