import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { usePostStatus } from '@src/data/api/credit-api/credit-api'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useCancelReasons, useSubmitFeedback } from '@src/data/api/feedback-api/feedback-api'
import { ECreditApplicationStatus, EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import DialogCloseButton from '@src/components/common/DialogCloseButton'
import FeedbackOption from '../FeedBackOption'
import buildSelectedWithdrawalReasonFeedbackDto from '../feedback-hooks'

type Props = {
  open: boolean
  handleClose: () => void
  creditApplicationId: string
  financingProgramId: EFinancingProgram
}
const CancelReasonsDialog = ({ open, handleClose, creditApplicationId, financingProgramId }: Props) => {
  const { t } = useTranslation()
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { handleSubmit } = useForm({
    mode: 'onBlur',
  })
  const [postCancelApp, isCancelling] = usePostStatus()
  const [badReviewReasons, isLoadingReasons] = useCancelReasons(resolvedLanguage!)
  const [selectedReason, setSelectedReason] = useState('')
  const [submitFeedback, isSubmitting, isSubmittingError] = useSubmitFeedback()

  const onSubmit = async () => {
    await postCancelApp({
      id: creditApplicationId,
      status: ECreditApplicationStatus.Cancelled,
      financingProgramId,
    })
    const dto = buildSelectedWithdrawalReasonFeedbackDto(selectedReason, creditApplicationId, financingProgramId, false)
    await submitFeedback(dto)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {t('withdrawalReasonDialog.title')}
        <DialogCloseButton onClose={handleClose} />
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Box sx={{ mb: 2, marginTop: '1rem', textAlign: 'center' }}>
          {isLoadingReasons && <CircularProgress sx={{ textAlign: 'center' }} title="" />}
          {!isLoadingReasons &&
            badReviewReasons?.map((r) => (
              <FeedbackOption
                key={r.id}
                id={r.id}
                text={r.text}
                selected={selectedReason}
                onChange={setSelectedReason}
              />
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <AsyncActionButton fullWidth={false} id="cancelConsent" startIcon={<CancelIcon />} onClick={handleClose}>
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton
          fullWidth={false}
          isPending={isSubmitting || isCancelling}
          isError={isSubmittingError}
          id="submitConsent"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={!selectedReason}
          startIcon={<DoneIcon />}
        >
          {t('viewCreditApplication.cancelApplication')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default CancelReasonsDialog
