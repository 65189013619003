import { Link, Typography } from '@mui/material'
import { companyConfigByFinancingProgramId } from '@src/data/creditapp-selectors'
import { EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'
import { Link as RouterDomLink } from 'react-router-dom'

interface Props {
  financingProgram: EFinancingProgram
}
const GetCreditContentConsentText = ({ financingProgram }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const config = companyConfigByFinancingProgramId(financingProgram)
  const link = resolvedLanguage === 'fr' ? config.privacyPolicyLinkFR : config.privacyPolicyLinkEN

  return (
    <Typography component="div">
      <p>{t('submission.iHaveInformedTheCustomer')}</p>
      <ol>
        <li>{t('submission.contentIsCorrect')}</li>
        <li>{t('submission.creditCheckSent')}</li>
        <li>{t('submission.createTemporaryNote')}</li>
        <li>{t('submission.creditReportSignedKept')}</li>
      </ol>
      <p>
        {t('submission.iHaveShownPrivacyPolicyToCustomer')}{' '}
        <Link component={RouterDomLink} to={link} target="_blank">
          {t('incomeVerification.startVerification.termsAndConditions')}
        </Link>
      </p>
    </Typography>
  )
}

export default GetCreditContentConsentText
