import {
  MutationFunction,
  QueryFunctionContext,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { EContractStatus, EFinancingProgram } from '@src/types/Constants'
import { ComputePersonalLoanFundingDto } from '@src/types/ComputePersonalLoanFundingDto'
import { ComputePersonalLoanFundingResultDto } from '@src/types/ComputePersonalLoanFundingResultDto'
import { isComputePersonalLoanDtoValid, isComputePossibleTermsDtoValid } from '@src/data/worksheet-selectors'
import { FilteredWorksheet } from '@src/types/FilteredWorksheet'
import { EditPersonalLoanWorksheetDto } from '@src/types/WorksheetSchema'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { AxiosError } from 'axios'
import { Contract } from '@src/types/Contract'
import { ComputeMonthTermFrequencyDto } from '@src/types/ComputeMonthTermFrequencyDto'
import { ComputeMonthlyTermsResponseDto } from '@src/types/ComputeMonthlyTermsResponseDto'
import { getApiClient } from '../api-client'

const SCOPE = 'worksheet'
const CREDIT_APP_SCOPE = 'credit-applications'
const SCOPEHOLIDAYS = 'holidays'
const SCOPEPERSOFUNDING = 'personalFunding'
const DETAIL = 'detail'
const MONTHLYTERMS = 'monthlyTerms'

const keysFactory = {
  amountForFrequency: (termsRequestParams: ComputeMonthTermFrequencyDto | null) =>
    [{ scope: MONTHLYTERMS, ...termsRequestParams }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  holidays: (year: number) => [{ scope: SCOPEHOLIDAYS, year }] as const,
  personalFunding: (id: string, dto: ComputePersonalLoanFundingDto) =>
    [{ scope: SCOPEPERSOFUNDING, id, ...dto }] as const,
  creditAppDetail: (id: string, financingProgramId: string) =>
    [{ scope: CREDIT_APP_SCOPE, entity: DETAIL, id, financingProgramId }] as const,
}

const GetHolidaysForYear = async ({
  queryKey: [{ year }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['holidays']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Date[]>(`/Holidays/${encodeURIComponent(year)}`)
  return response.data
}

export function useGetHolidaysForYear(year: number): [Date[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.holidays(year),
    queryFn: GetHolidaysForYear,
  })

  return [data!, isFetching]
}
const GetPersonalFunding = async ({
  queryKey: [{ id, ...dto }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['personalFunding']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<ComputePersonalLoanFundingResultDto>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(id)}/ComputePersonalLoanFunding/`,
    {
      params: dto,
    },
  )
  return response.data
}

export function useGetPersonalFunding(
  id: string,
  dto: ComputePersonalLoanFundingDto,
): [ComputePersonalLoanFundingResultDto, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.personalFunding(id, dto),
    queryFn: GetPersonalFunding,
    enabled: isComputePersonalLoanDtoValid(dto),
  })

  return [data!, isFetching]
}

const sendSignatureLink: MutationFunction<
  Contract,
  { financingProgramId: EFinancingProgram; creditApplicationId: string }
> = async ({ financingProgramId, creditApplicationId }) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<Contract>(
    `/Contract/${financingProgramId}/${creditApplicationId}/SendSignatureLink`,
  )
  return response.data
}

export function useSendSignatureLink(
  financingProgramId: EFinancingProgram,
  creditApplicationId: string,
): [UseMutateAsyncFunction<Contract, Error, string, unknown>, boolean, boolean, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess } = useMutation<Contract, Error, string>({
    mutationFn: () => sendSignatureLink({ financingProgramId, creditApplicationId }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        keysFactory.creditAppDetail(creditApplicationId, financingProgramId),
        (oldData: CreditApplication) => {
          return {
            ...oldData,
            contract: data,
          }
        },
      )
    },
  })

  return [mutateAsync, isPending, isError, isSuccess]
}

const postWorksheet: MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.post<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )

  return response.data
}

export function usePostWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, mutateAsync, error, reset } = useMutation({
    mutationFn: postWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(
        keysFactory.creditAppDetail(data.creditApplicationId, EFinancingProgram.Personal),
        (oldData: CreditApplication) => {
          return {
            ...oldData,
            worksheet: data,
            updatedOn: data.updatedOn,
            contract: { ...oldData.contract, status: EContractStatus.notReady },
          }
        },
      )
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}

const putWorksheet: MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }> = async ({
  worksheet,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.put<FilteredWorksheet>(
    `/Worksheet/${EFinancingProgram.Personal}/${encodeURIComponent(worksheet.creditApplicationId!)}`,
    worksheet,
  )
  return response.data
}

export function usePutWorksheet(): [
  MutationFunction<FilteredWorksheet, { worksheet: EditPersonalLoanWorksheetDto }>,
  boolean,
  boolean,
  AxiosError<{ message: string }> | null,
  () => void,
] {
  const queryClient = useQueryClient()
  const { isPending, isError, error, mutateAsync, reset } = useMutation({
    mutationFn: putWorksheet,
    onSuccess: (data) => {
      queryClient.setQueryData(
        keysFactory.creditAppDetail(data.creditApplicationId, EFinancingProgram.Personal),
        (oldData: CreditApplication) => {
          return {
            ...oldData,
            worksheet: data,
            updatedOn: data.updatedOn,
            contract: { ...oldData.contract, status: EContractStatus.notReady },
          }
        },
      )
    },
  })

  return [mutateAsync, isPending, isError, error as AxiosError<{ message: string }>, reset]
}

const GetPossibleTerms = async ({
  queryKey: [termsRequestParams],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['amountForFrequency']>>) => {
  const response = await getApiClient().post(
    `Worksheet/${EFinancingProgram.Personal}/CalculateMonthlyTerms`,
    termsRequestParams,
  )

  return response.data as ComputeMonthlyTermsResponseDto
}

export function useGetPossibleTerms(
  computeParams: ComputeMonthTermFrequencyDto,
): [ComputeMonthlyTermsResponseDto, boolean] {
  const { isPending, data } = useQuery({
    queryKey: [...keysFactory.amountForFrequency(computeParams)],
    queryFn: GetPossibleTerms,
    enabled: isComputePossibleTermsDtoValid(computeParams),
  })

  return [data!, isPending]
}
