import { createContext, useContext, useMemo } from 'react'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { useParams } from 'react-router-dom'
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles'
import { enUS, frFR } from '@mui/x-data-grid/locales'
import {
  ECompany,
  EThemeMode,
  FinancingCompanyConfigOptions,
  FinancingCompanyConfigs,
  PREFERS_MODE_SETTING,
} from '@src/types/Constants'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import LinkComponent from '@src/components/common/LinkComponent'
import { useUser } from './AppContext'

export const SYSTEM_THEME_MODE =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? EThemeMode.Dark : EThemeMode.Light
export const CACHED_THEME_MODE: EThemeMode =
  (localStorage.getItem(PREFERS_MODE_SETTING) as EThemeMode) ?? SYSTEM_THEME_MODE

export const GLOBAL_OVERRIDES: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkComponent,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiIconButton: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiInputBase: {
      defaultProps: {
        // Needed to prevent adding a global style for every input field
        disableInjectingGlobalStyles: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        LinkComponent,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
        }),
        columnHeaderTitle: {
          fontWeight: 'bold',
        },
        sortIcon: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
        }),
        columnSeparator: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
          ':hover': {
            color: theme.palette.secondary.contrastText,
          },
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-main :first-of-type': {
            borderRadius: '0 !important',
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiTab: {
      defaultProps: {
        LinkComponent,
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
  },
}

// Color picking documentation https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// Custom color: https://mui.com/material-ui/customization/palette/#custom-colors
// Generating token color: https://mui.com/material-ui/customization/color/#playground
const createCustomTheme = (mode: EThemeMode, config: FinancingCompanyConfigOptions): Theme => {
  const configTheme = config.theme[mode]
  return createTheme(
    {
      ...GLOBAL_OVERRIDES,
      phone: config.phone,
      logo: configTheme.logo,
      logoContrast: configTheme.logoContrast,
      favicon: configTheme.favicon,
      palette: {
        primary: configTheme.primary,
        secondary: configTheme.secondary,
        mode,
        success: { main: '#90ee90', contrastText: '#018001' },
        info: { main: '#a2caf3' },
      },
    },
    frFR,
    enUS,
  )
}

export const ColorModeContext = createContext({ toggleColorMode: () => {} })

export function useColorMode() {
  return useContext(ColorModeContext)
}

export function useCustomTheme(mode: EThemeMode): Theme | null {
  const { financingCompanyId } = useParams<{ financingCompanyId: ECompany }>()
  const user = useUser()
  const [merchant] = useMerchantById(user?.merchantId ?? '')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentFinancingCompanyId: ECompany | null =
    financingCompanyId ?? merchant?.financingCompanyId ?? ECompany.IFinance

  return useMemo(() => {
    return currentFinancingCompanyId
      ? createCustomTheme(mode, FinancingCompanyConfigs[currentFinancingCompanyId])
      : null
  }, [mode, currentFinancingCompanyId])
}
