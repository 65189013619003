import { CustomerFeedbackDto } from '@src/data/api/feedback-api/feedback-api'
import { EFinancingProgram } from '@src/types/Constants'

const buildSelectedWithdrawalReasonFeedbackDto = (
  selectedReason: string,
  creditApplicationId: string,
  financingProgramId: EFinancingProgram,
  contactClient: boolean,
): CustomerFeedbackDto => {
  return {
    financingProgramId,
    withdrawalReasonId: selectedReason,
    contactClient,
    isSatisfied: false,
    badReviewReasonId: '',
    creditApplicationId,
  }
}

export default buildSelectedWithdrawalReasonFeedbackDto
