import AsyncActionButton from '@src/components/AsyncActionButton'
import { EFinancingProgram } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

type Props = {
  id: string
  financingProgramId: EFinancingProgram
  coapp: ApplicantDto | null
}
const AddChangeCoappButton = ({ id, financingProgramId, coapp }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const coAppButtonText = coapp ? t('viewCreditApplication.changeCoapp') : t('viewCreditApplication.addCoapp')
  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      onClick={() => {
        navigate(`/credits/${financingProgramId}/${id}/coapplicant`)
      }}
    >
      {coAppButtonText}
    </AsyncActionButton>
  )
}

export default AddChangeCoappButton
