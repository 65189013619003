import { Navigate, Route, Routes } from 'react-router-dom'
import AuthPage from '../Authentication'
import BrowseCreditApplicationPage from '../BrowseCreditApplicationPage'
import CreateCreditApplicationPage from '../CreditApplicationPage/CreateCreditApplicationPage'
import AddCoapplicantPage from '../AddCoapplicantPage/components/AddCoapplicantPage'
import EditCreditApplicationPage from '../CreditApplicationPage/EditCreditApplicationPage'
import FundingSummary from '../FundingSummary'
import CreateWorksheetPage from '../CreateWorksheetPage/CreateWorksheetPage'
import TemplateApp from './TemplateApp'
import ViewCreditApplicationPage from '../ViewCreditApplicationPage'
import CalculatorPage from '../CalculatorPage'

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<TemplateApp />}>
        <Route path="/credits" element={<BrowseCreditApplicationPage />} />
        <Route path="/calculator" element={<CalculatorPage />} />
        <Route path="/credits/:financingProgramId/add" element={<CreateCreditApplicationPage />} />
        <Route path="/credits/:financingProgramId/:id">
          <Route path="" element={<ViewCreditApplicationPage />} />
          <Route path="/credits/:financingProgramId/:id/coapplicant" element={<AddCoapplicantPage />} />
          <Route path="edit" element={<EditCreditApplicationPage />} />
          <Route path="worksheet" element={<CreateWorksheetPage />} />
          <Route path="funding-summary" element={<FundingSummary />} />
        </Route>
      </Route>
      <Route path="/" element={<Navigate to="/credits" replace />} />
      <Route path="/auth-page" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/credits" replace />} />
    </Routes>
  )
}

export default TopLevelRoutes
