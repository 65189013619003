import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import {
  Alert,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { Merchant } from '@src/types/Merchant'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'
import InputTextField from './InputTextField'
import { useMerchantSelection, useMerchantsFilter } from './SelectMerchantsDialog-hooks'

type Props = {
  open: boolean
  title: string
  label: string
  merchantsTotal: Merchant[]
  merchantsSelected: Merchant[]
  onConfirm: (merchants: Merchant[]) => void
  onCancel: () => void
}

const SelectMerchantsDialog = ({
  open,
  title,
  label,
  merchantsTotal,
  merchantsSelected,
  onConfirm,
  onCancel,
}: Props) => {
  const { t } = useTranslation()
  const { filteredMerchants, query, setQuery } = useMerchantsFilter(merchantsTotal)
  const { selectedMerchants, setSelectedMerchants, handleItemChecked } = useMerchantSelection(merchantsSelected)

  const cancel = () => {
    setQuery('')
    setSelectedMerchants(merchantsSelected)
    onCancel()
  }

  const confirmSelection = () => {
    onConfirm(selectedMerchants)
    setQuery('')
  }

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <InputTextField id="queryField" value={query} onChange={(e) => setQuery(e.target.value)} label={label} />
          {query && query.trim() && filteredMerchants.length === 0 && (
            <Alert variant="filled" severity="error">
              {t('common.searchYieldsNoResults')}
            </Alert>
          )}
          {query === '' && (
            <Alert variant="filled" severity="info">
              {t('common.typeToStartSearch')}
            </Alert>
          )}
          {filteredMerchants.length > 0 && (
            <List>
              {filteredMerchants.map((merchant) => (
                <ListItemButton
                  key={merchant.id}
                  onClick={() => handleItemChecked(merchant, !selectedMerchants.some((m) => m.id === merchant.id))}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedMerchants.some((m) => m.id === merchant.id)}
                      tabIndex={-1}
                      onChange={(e) => handleItemChecked(merchant, e.target.checked)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={merchant.name} />
                </ListItemButton>
              ))}
            </List>
          )}
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {selectedMerchants.map((merchant: Merchant) => (
              <Chip
                key={merchant.id}
                label={merchant.name}
                size="small"
                onDelete={() => handleItemChecked(merchant, false)}
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <AsyncActionButton startIcon={<CancelIcon />} color="error" onClick={cancel}>
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton variant="contained" color="primary" startIcon={<DoneIcon />} onClick={confirmSelection}>
          {t('common.confirm')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(SelectMerchantsDialog)
