import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

interface Props {
  isLoading: boolean
  withBackdrop?: boolean
}

const PageSpinner = ({ isLoading, withBackdrop = false }: Props) => {
  return (
    <Backdrop open={isLoading} invisible={!withBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress />
    </Backdrop>
  )
}

export default PageSpinner
