import { Grid, Box, Divider, Typography } from '@mui/material'

import { DatePickerInput } from '@src/components/DatePicker'
import InputTextFieldWithMask from '@src/components/InputMask'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import {
  Constants,
  EApplicantType,
  EGenderList,
  ELanguageList,
  EMaritalStatusList,
  EMask,
  ERelationToApplicantList,
} from '@src/types/Constants'
import { ApplicantDto, CreditApplication } from '@src/types/CreditApplicationSchema'
import { addYears } from 'date-fns'
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  Merge,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  register: UseFormRegister<CreditApplication | ApplicantDto>
  errors: Merge<FieldError, FieldErrors<ApplicantDto>> | undefined
  name: 'coApplicant.' | 'applicant.' | ''
  formControl: Control<CreditApplication | ApplicantDto>
  setValue: UseFormSetValue<CreditApplication | ApplicantDto>
  getValues: UseFormGetValues<CreditApplication | ApplicantDto>
  prohibitedPhone?: string | null
  trigger: UseFormTrigger<CreditApplication | ApplicantDto>
}

const ApplicantInformationParameters = ({
  register,
  errors,
  name,
  formControl,
  setValue,
  trigger,
  getValues,
  prohibitedPhone,
}: Props) => {
  const { t } = useTranslation()
  const isCoApplicant = name !== 'applicant.'

  const handlePhoneOnchange = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    const phone = value.substring(1).replace(/-/g, '')
    event.target.setCustomValidity('')
    if ((!name || name.includes(EApplicantType.Coapplicant)) && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.coappSameMobileError'))
    } else if (name.includes(EApplicantType.Applicant) && prohibitedPhone === phone) {
      event.target.setCustomValidity(t('common.errors.applicantSameMobileError'))
    }
    setValue(`${name}cellPhone`, phone, { shouldValidate: true })
    event.target.reportValidity()
    if (getValues('coApplicant')) {
      await Promise.all([trigger('applicant.cellPhone'), trigger('coApplicant.cellPhone')])
    }
  }

  return (
    <Box>
      <input type="hidden" {...register(`${name}id`)} />
      <Divider style={{ fontSize: 25 }}>
        <Typography variant="h5" gutterBottom>
          {t('editCreditApplication.information.label')}
        </Typography>
      </Divider>

      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <InputTextField
            id="applicantFirstName"
            error={errors?.firstName}
            label={t('editCreditApplication.information.firstName')}
            {...register(`${name}firstName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <InputTextField
            id="applicantMiddleName"
            error={errors?.middleName}
            label={t('editCreditApplication.information.middleName')}
            {...register(`${name}middleName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <InputTextField
            id="applicantLastName"
            error={errors?.lastName}
            label={t('editCreditApplication.information.lastName')}
            {...register(`${name}lastName`)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <DatePickerInput
            name={`${name}birthDate`}
            control={formControl}
            label={t('editCreditApplication.information.birthDate')}
            error={errors?.birthDate}
            minDate={addYears(new Date(), -Constants.MaxYearsToDisplay)}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={3}>
          <Controller
            name={`${name}sin`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="sin"
                mask={EMask.sinMask}
                error={errors?.sin}
                label={t('editCreditApplication.information.sin')}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectComponent
            valueList={EGenderList.map((value) => ({ label: `genderType.${value}`, value }))}
            label={t('editCreditApplication.information.genderId') as string}
            {...register(`${name}genderId`)}
            error={errors?.genderId}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectComponent
            valueList={EMaritalStatusList.map((value) => ({ label: `maritalStatus.${value}`, value }))}
            label={t('editCreditApplication.information.maritalStatusId') as string}
            {...register(`${name}maritalStatusId`)}
            error={errors?.maritalStatusId}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectComponent
            valueList={ELanguageList.map((value) => ({ label: `language.${value}`, value }))}
            label={t('editCreditApplication.information.languageId') as string}
            {...register(`${name}languageId`)}
            error={errors?.languageId}
          />
        </Grid>
        {isCoApplicant && (
          <Grid item xs={12} md={3}>
            <SelectComponent
              valueList={ERelationToApplicantList.map((value) => ({
                label: `relationToApplicant.${value}`,
                value,
              }))}
              label={t('editCreditApplication.information.relationWithApplicant') as string}
              {...register(`${name}relationWithApplicant`)}
              error={errors?.relationWithApplicant}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} rowSpacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} md={6}>
          <Controller
            name={`${name}homePhone`}
            control={formControl}
            render={({ field }) => (
              <InputTextFieldWithMask
                id="homePhone"
                inputMode="tel"
                mask={EMask.phoneNumberMask}
                error={errors?.homePhone}
                label={t('editCreditApplication.information.homePhone')}
                {...field}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name={`${name}cellPhone`}
            control={formControl}
            render={() => (
              <InputTextFieldWithMask
                defaultValue={getValues(`${name}cellPhone`)}
                id="cellPhone"
                mask={EMask.phoneNumberMask}
                error={errors?.cellPhone}
                label={t('editCreditApplication.information.cellPhone')}
                onBlur={handlePhoneOnchange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            id="email"
            type="email"
            error={errors?.email}
            label={t('editCreditApplication.information.email')}
            {...register(`${name}email`)}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export default ApplicantInformationParameters
