import AsyncActionButton from '@src/components/AsyncActionButton'
import { useResetBankAccountRequest } from '@src/data/api/credit-api/credit-api'
import { BankAccountRequestDto } from '@src/data/api/type'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'

type Props = {
  financingProgramId: EFinancingProgram
  applicantType: EApplicantType
  id: string
}

const ResetBankAccountButton = ({ applicantType, id, financingProgramId }: Props) => {
  const { t } = useTranslation()
  const [resetBankAccount, isResetingBankAccount, isError, isSuccess] = useResetBankAccountRequest()

  const onResetBankAccount = async () => {
    const bankRequestDto = {
      applicantType,
      creditApplicationParams: { creditApplicationId: id, financingProgramId },
    } as BankAccountRequestDto
    await resetBankAccount(bankRequestDto)
  }

  return (
    <AsyncActionButton
      variant="contained"
      color="primary"
      isPending={isResetingBankAccount}
      isError={isError}
      isSuccess={isSuccess}
      onClick={onResetBankAccount}
    >
      {t('incomeVerification.resetBankAccount')}
    </AsyncActionButton>
  )
}

export default ResetBankAccountButton
