import { Address } from '@src/types/AddressSchema'
import { Constants, ERelationToApplicant } from '@src/types/Constants'
import { ApplicantJob, ApplicantOtherIncome, CreditApplication } from '@src/types/CreditApplicationSchema'
import { sumBy } from 'lodash-es'

function strCompare(param1: string | null | undefined, param2: string | null | undefined): boolean {
  return (param1 ?? '') === (param2 ?? '')
}

function DoesApplicantLiveWithCoapplicant(application: CreditApplication): boolean {
  const applicantAddress = application.applicant.currentAddress as Address
  const coApplicantAddress = application.coApplicant?.currentAddress as Address

  return (
    strCompare(applicantAddress.civicNumber, coApplicantAddress.civicNumber) &&
    strCompare(applicantAddress.apartment, coApplicantAddress.apartment) &&
    strCompare(applicantAddress.street, coApplicantAddress.street) &&
    strCompare(applicantAddress.city, coApplicantAddress.city) &&
    strCompare(applicantAddress.country, coApplicantAddress.country) &&
    strCompare(applicantAddress.stateIso, coApplicantAddress.stateIso) &&
    strCompare(applicantAddress.postalCode, coApplicantAddress.postalCode)
  )
}

export type BusinessError = {
  message: string
  params?: Record<string, string | number>
}

export function validateCreditApplicationCanBeSubmittedForReview(application: CreditApplication): BusinessError[] {
  const ret: BusinessError[] = []
  const applicantIncomes =
    Number(sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
    Number(sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

  const coapplicantIncomes =
    Number(sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary)) +
    Number(sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount))

  const programConfig = Constants.financingProgramConfigs[application.financingProgramId]
  const coapplicantIsSpouse =
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.Spouse ||
    application.coApplicant?.relationWithApplicant === ERelationToApplicant.CommonLaw

  if (coapplicantIsSpouse && DoesApplicantLiveWithCoapplicant(application)) {
    if (
      (applicantIncomes < programConfig.minIncome || coapplicantIncomes < programConfig.minIncome) &&
      applicantIncomes + coapplicantIncomes < programConfig.minCombinedIncome
    )
      ret.push({
        message: 'editCreditApplication.errors.minIncomeNotMetCouple',
        params: { minIncome: programConfig.minIncome, minCombinedIncome: programConfig.minCombinedIncome },
      })
  } else if (applicantIncomes < programConfig.minIncome && coapplicantIncomes < programConfig.minIncome) {
    ret.push({
      message: 'editCreditApplication.errors.minIncomeNotMet',
      params: { minIncome: programConfig.minIncome },
    })
  }
  return ret
}
