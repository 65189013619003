import { CircularProgress } from '@mui/material'
import { useSkipBankAccountRequestRequirements } from '@src/data/api/credit-api/credit-api'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  applicantType: EApplicantType
}
const SkipBankAccountRequestRequirements = ({ creditApplicationId, financingProgramId, applicantType }: Props) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const [skipBankAccountRequestRequirements, isFetchingSkipBankAccountRequestRequirements] =
    useSkipBankAccountRequestRequirements({ creditApplicationId, financingProgramId })

  const filteredRequirements = skipBankAccountRequestRequirements?.filter((r) => r.applicantType === applicantType)

  return (
    <>
      {isFetchingSkipBankAccountRequestRequirements && <CircularProgress />}
      {filteredRequirements?.map((x) => <div key={x.id}>{resolvedLanguage === 'fr' ? x.nameFr : x.nameEn}</div>)}
    </>
  )
}

export default SkipBankAccountRequestRequirements
