import { Container } from '@mui/material'
import { EFinancingProgram } from '@src/types/Constants'
import ProductsCalculator from './components/ProductsCalculator'
import { useUserSupportsProgram } from '../ViewCreditApplicationPage/credit-hooks'

const CalculatorPage = () => {
  const supportsProducts = useUserSupportsProgram(EFinancingProgram.Products)

  return (
    <Container maxWidth="sm">
      {/* Render calcs for supported programs */}
      {supportsProducts && <ProductsCalculator />}
    </Container>
  )
}

export default CalculatorPage
