import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import TopLevelRoutes from './Routes'
import AlertBannerOnDeprecatedStatus from './components/AlertBannerOnDeprecatedStatus'
import ThemeProvider from './ThemeProvider'
import UserProvider from './UserProvider'

const App = () => {
  return (
    <UserProvider>
      <ThemeProvider>
        <Router>
          <AlertBannerOnDeprecatedStatus />
          <TopLevelRoutes />
        </Router>
      </ThemeProvider>
    </UserProvider>
  )
}

export default App
