import { InputBaseComponentProps, StandardTextFieldProps } from '@mui/material'
import React, { HTMLProps, useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { EMask } from '@src/types/Constants'
import InputTextField from './InputTextField'

interface CustomProps extends Omit<InputBaseComponentProps, 'onChange'> {
  name: string
  type: EMask
  onChange: (event: { target: { name: string; value: string } }) => void
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { type: mask, name, onChange, ...other } = props

  const { type, inputMode } = useMemo<{
    type: HTMLProps<HTMLInputElement>['type']
    inputMode: HTMLProps<HTMLInputElement>['inputMode']
  }>(() => {
    switch (mask) {
      case EMask.phoneNumberMask:
        return { type: 'tel', inputMode: 'tel' }
      case EMask.sinMask:
        return { type: 'text', inputMode: 'numeric' }
      default:
        return { type: 'text', inputMode: 'text' }
    }
  }, [mask])

  return (
    <IMaskInput
      {...other}
      type={type}
      inputMode={inputMode}
      mask={mask}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name, value } })}
      overwrite
    />
  )
})

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
  mask: string
}

const InputTextFieldWithMask = React.forwardRef<HTMLInputElement, Props>(function InputTextFieldWithMask(
  { error, mask, ...othersProps },
  ref,
) {
  return (
    <InputTextField
      error={error}
      {...othersProps}
      ref={ref}
      InputProps={{
        type: mask,
        inputComponent: TextMaskCustom,
      }}
    />
  )
})

export default InputTextFieldWithMask
