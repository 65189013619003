import { EApplicantType, EDocumentStatus } from '@src/types/Constants'
import { RequiredDocument } from '@src/types/RequiredDocument'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

export function useDocumentStatus(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  return useMemo(() => {
    let statusMessage: string
    let chipColor: ChipColor

    switch (documentRequired.status) {
      case EDocumentStatus.Approved:
        statusMessage = t('documentStatuses.approved')
        chipColor = 'success'
        break
      case EDocumentStatus.AwaitingApproval:
        statusMessage = t('documentStatuses.awaitingApproval')
        chipColor = 'info'
        break
      case EDocumentStatus.Refused:
        statusMessage = t('documentStatuses.refused')
        chipColor = 'error'
        break
      case EDocumentStatus.Incomplete:
        statusMessage = t('documentStatuses.incomplete')
        chipColor = 'warning'
        break
      default:
        statusMessage = t('documentStatuses.awaitingUpload')
        chipColor = 'warning'
    }
    return { statusMessage, chipColor }
  }, [documentRequired.status, t])
}

export function useFullNameText(documentRequired: RequiredDocument) {
  const { t } = useTranslation()

  const fullNameText = useMemo(() => {
    let applicantSuffix = ''
    if (documentRequired.applicantType === EApplicantType.Applicant) {
      applicantSuffix = ` - ${t('common.applicant')}`
    } else if (documentRequired.applicantType === EApplicantType.Coapplicant) {
      applicantSuffix = ` - ${t('common.coApplicant')}`
    }

    return t('common.currentLanguage') === 'Fr'
      ? `${documentRequired.nameFr}${applicantSuffix}`
      : `${documentRequired.nameEn}${applicantSuffix}`
  }, [documentRequired, t])

  return fullNameText
}
