import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'

import React, { Dispatch } from 'react'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'

type Props = {
  openConsentDialog: boolean
  setOpenConsentDialog: Dispatch<boolean>
  consentChecked: boolean
  handleCheckboxChange: SwitchBaseProps['onChange']
  handleConfirm: () => void
  dialogContent: React.ReactNode
  buttonText: React.ReactNode
  checkBoxText?: string
}

const CreditConsentDialog = ({
  openConsentDialog,
  setOpenConsentDialog,
  consentChecked,
  handleCheckboxChange,
  handleConfirm,
  dialogContent,
  buttonText,
  checkBoxText,
}: Props) => {
  const { t } = useTranslation()
  return (
    <Dialog
      disablePortal
      transitionDuration={0}
      keepMounted
      open={openConsentDialog}
      onClose={() => setOpenConsentDialog(false)}
    >
      <DialogTitle>{t('submission.submission')}</DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          {dialogContent}
          <FormControlLabel
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
            control={
              <Checkbox id="consentCheckbox" checked={consentChecked} onChange={handleCheckboxChange} color="primary" />
            }
            label={checkBoxText ?? t('submission.agreeToTerms')}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AsyncActionButton
          fullWidth={false}
          id="cancelConsent"
          onClick={() => setOpenConsentDialog(false)}
          startIcon={<CancelIcon />}
        >
          {t('common.cancel')}
        </AsyncActionButton>
        <AsyncActionButton
          fullWidth={false}
          id="submitConsent"
          onClick={handleConfirm}
          variant="contained"
          disabled={!consentChecked}
          startIcon={<DoneIcon />}
        >
          {buttonText}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CreditConsentDialog)
