import { Box, Divider, Stack, Typography } from '@mui/material'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EApplicantType, EFinancingProgram, EProvince } from '@src/types/Constants'
import { useEffect } from 'react'
import AddressComponent from './components/AddressComponent'
import ApplicantExpensesSection from './components/ApplicantExpenseComponent'
import ApplicantIncomeSection from './components/ApplicantIncomeComponent'
import ApplicantInformationParameters from './components/ApplicantInformationComponent'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  errors: FieldErrors<ApplicantDto> | undefined
  applicantType?: EApplicantType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  editDisabled: boolean
  prohibitedPhone?: string | null
  financingProgramId: EFinancingProgram
}

const ApplicantParameters = ({
  register,
  watch,
  setValue,
  getValues,
  trigger,
  errors,
  applicantType,
  formControl,
  editDisabled,
  prohibitedPhone,
  financingProgramId,
}: Props) => {
  const { t } = useTranslation()
  const typedErrors = errors as FieldErrors<ApplicantDto>
  const prefix: 'coApplicant.' | 'applicant.' | '' = applicantType
    ? (applicantType.concat('.') as 'coApplicant.' | 'applicant.')
    : ''

  useEffect(() => {
    setValue(`${applicantType}.financingProgramId`, financingProgramId)
  }, [financingProgramId, applicantType, setValue])

  const applicantProvince = watch('applicant.currentAddress.stateIso') as EProvince
  const useExpenseIncomeSections =
    financingProgramId !== EFinancingProgram.Products ||
    (financingProgramId === EFinancingProgram.Products &&
      applicantProvince === EProvince.quebec &&
      applicantType === EApplicantType.Applicant)
  const toolTip = editDisabled ? t('applicationHeader.tooltips.lockedForModification') : ''

  return (
    <Stack spacing={2}>
      <ApplicantInformationParameters
        register={register}
        errors={typedErrors}
        trigger={trigger}
        name={prefix}
        formControl={formControl}
        setValue={setValue}
        getValues={getValues}
        prohibitedPhone={prohibitedPhone}
      />
      <Box>
        <Divider>
          <Typography variant="h5" gutterBottom>
            {t('editCreditApplication.address.label')}
          </Typography>
        </Divider>
        <AddressComponent
          formControl={formControl}
          register={register}
          errors={typedErrors?.currentAddress}
          name={`${prefix}currentAddress`}
        />
      </Box>

      {useExpenseIncomeSections && (
        <>
          <Divider>
            <Typography variant="h5">{t('editCreditApplication.expenses.label')}</Typography>
          </Divider>
          <ApplicantExpensesSection
            register={register}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            errors={typedErrors?.expenses}
            name={`${prefix}expenses`}
            showDetailedForm={false}
            disabled={editDisabled}
            toolTip={toolTip}
          />
          <ApplicantIncomeSection
            register={register}
            errors={typedErrors?.otherIncomes}
            formControl={formControl}
            name={prefix}
            disableEdit={editDisabled}
            toolTip={toolTip}
          />
        </>
      )}
    </Stack>
  )
}
export default ApplicantParameters
