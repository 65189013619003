import { Typography } from '@mui/material'

import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { useTranslation } from 'react-i18next'
import CancelCreditAppButton from '../CancelCreditAppButton'
import AddChangeCoappButton from '../AddCoappButton'

type Props = {
  creditApp: CreditApplication
}

const PrequalDeclined = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="body1">{t('viewCreditApplication.clientDeclined')}</Typography>
      <AddChangeCoappButton
        id={creditApp.id}
        financingProgramId={creditApp.financingProgramId}
        coapp={creditApp.coApplicant}
      />
      <CancelCreditAppButton creditApp={creditApp} />
    </>
  )
}

export default PrequalDeclined
