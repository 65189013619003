import { Button, ButtonProps, LinearProgress, Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import { useCallback, useEffect, useState } from 'react'
import { delay } from 'lodash-es'
import { useTranslation } from 'react-i18next'

interface Props extends ButtonProps {
  isPending?: boolean
  isError?: boolean
  isSuccess?: boolean
  successWaitingTime?: number
}

const AsyncActionButton = ({
  isPending,
  isError,
  isSuccess,
  successWaitingTime = 3000,
  disabled,
  children,
  fullWidth = true,
  ...otherProps
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [showErrorIcon, setShowErrorIcon] = useState<boolean>(false)
  const [showSuccessIcon, setShowSuccessIcon] = useState<boolean>(false)

  useEffect(() => {
    if (isError) {
      setShowErrorIcon(true)
      delay(() => setShowErrorIcon(false), 3000)
    }
    if (isSuccess) {
      setShowSuccessIcon(true)
      delay(() => setShowSuccessIcon(false), successWaitingTime)
    }
  }, [isError, isSuccess, successWaitingTime])

  const getIcon = useCallback(() => {
    if (!isPending && showErrorIcon) return <ErrorIcon sx={{ marginLeft: 0.5 }} fontSize="small" />
    if (!isPending && showSuccessIcon) return <CheckIcon sx={{ marginLeft: 0.5 }} fontSize="small" />
    return null
  }, [isPending, showErrorIcon, showSuccessIcon])

  return (
    <Button
      fullWidth={fullWidth}
      type="button"
      {...otherProps}
      disabled={isPending || disabled || showSuccessIcon}
      endIcon={getIcon()}
    >
      <Stack>
        {isPending && <LinearProgress />}
        {showSuccessIcon ? t('common.successful') : children}
      </Stack>
    </Button>
  )
}

export default AsyncActionButton
