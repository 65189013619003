/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Stack } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { EBeneficiaryType, EServiceCategory } from '@src/types/Constants'
import { CreditApplication } from '@src/types/CreditApplicationSchema'
import { Merchant } from '@src/types/Merchant'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  isBanner: boolean
  merchantsList: Merchant[]
  setValue: UseFormSetValue<any>
  errors: FieldErrors<CreditApplication> | undefined
  merchant: Merchant | null
  loanPurposeId: EServiceCategory
  register: UseFormRegister<any>
  isGoodAndServiceLoanPurpose: boolean
  beneficiaryTypes: SelectValueListItem[]
  isVeterinaryLoanPurpose: boolean
  disableOtherName: boolean
}

const FinancingAmountAndBeneficiary = ({
  isBanner,
  merchantsList,
  setValue,
  errors,
  merchant,
  loanPurposeId,
  register,
  isGoodAndServiceLoanPurpose,
  beneficiaryTypes,
  isVeterinaryLoanPurpose,
  disableOtherName,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        {isBanner && (
          <Autocomplete
            id="merchantList"
            options={merchantsList}
            onChange={(event: any, selectedMerchant: Merchant | null) => {
              if (selectedMerchant) {
                setValue('merchantId', selectedMerchant.id, { shouldValidate: true })
                setValue('loanPurposeId', selectedMerchant.serviceCategory ?? '', { shouldValidate: true })
                if (selectedMerchant.serviceCategory === EServiceCategory.GoodsAndServices) {
                  setValue('beneficiaryTypeId', EBeneficiaryType.Applicant, { shouldValidate: true })
                  setValue('otherBeneficiaryFirstName', null, { shouldValidate: true })
                  setValue('otherBeneficiaryLastName', null, { shouldValidate: true })
                }
              }
            }}
            disabled={!isBanner}
            fullWidth
            autoHighlight
            getOptionLabel={(option) => option?.name ?? ''}
            noOptionsText={t('common.searchYieldsNoResults')}
            renderInput={(merchantList) => (
              <InputTextField
                {...merchantList}
                id="merchant"
                label={t('common.merchant')}
                inputProps={{
                  ...merchantList.inputProps,
                }}
                error={errors?.merchantId}
              />
            )}
          />
        )}
        {!isBanner && (
          <InputTextField
            id="merchant"
            value={merchant?.name ?? ''}
            disabled
            label={t('common.merchant')}
            error={errors?.merchantId}
          />
        )}
        <InputTextField
          id="loanPurposeId"
          value={loanPurposeId ? t(`serviceCategory.${loanPurposeId}`) : ''}
          disabled
          label={t('editCreditApplication.loanPurpose')}
          {...register('loanPurposeId')}
          error={errors?.loanPurposeId}
        />
        <InputTextField
          id="amountRequested"
          InputProps={{
            endAdornment: '$',
          }}
          error={errors?.requestedLoanAmount}
          label={t('editCreditApplication.amountRequested')}
          {...register('requestedLoanAmount')}
        />
      </Stack>
      {!isGoodAndServiceLoanPurpose && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
          <SelectComponent
            valueList={beneficiaryTypes}
            label={t('editCreditApplication.beneficiary.beneficiary') as string}
            disabled={isVeterinaryLoanPurpose}
            {...register('beneficiaryTypeId')}
            error={errors?.beneficiaryTypeId}
          />
          {!isVeterinaryLoanPurpose && (
            <InputTextField
              id="otherBeneficiaryFirstName"
              error={errors?.otherBeneficiaryFirstName}
              label={t('editCreditApplication.beneficiary.otherBeneficiaryFirstName')}
              disabled={disableOtherName}
              {...register('otherBeneficiaryFirstName')}
              inputProps={{ maxLength: 50 }}
            />
          )}
          <InputTextField
            id="otherBeneficiaryLastName"
            error={errors?.otherBeneficiaryLastName}
            label={
              !isVeterinaryLoanPurpose
                ? t('editCreditApplication.beneficiary.otherBeneficiaryLastName')
                : t('editCreditApplication.beneficiary.animalName')
            }
            disabled={disableOtherName}
            {...register('otherBeneficiaryLastName')}
            inputProps={{ maxLength: 50 }}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default FinancingAmountAndBeneficiary
