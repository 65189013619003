import { Stack, Tab, Tabs } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import TabPanel from '@src/components/TabPanel'
import { useCreateBankAccountRequestAndSendUrl, useSkipBankAccountRequest } from '@src/data/api/credit-api/credit-api'
import { BankAccountRequestDto } from '@src/data/api/type'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import { ApplicantDto } from '@src/types/CreditApplicationSchema'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  applicant: ApplicantDto
  hasIncomeDocumentsPendingUpload: boolean
  hasIncomeDocumentsPendingReview: boolean
  hasIncomeDocumentsApproved: boolean
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  canSkipFlinks: boolean
  canEdit: boolean
}

const StartIncomeVerification = ({
  applicant,
  hasIncomeDocumentsPendingUpload,
  hasIncomeDocumentsPendingReview,
  hasIncomeDocumentsApproved,
  creditApplicationId,
  financingProgramId,
  canSkipFlinks,
  canEdit,
}: Props) => {
  const { t } = useTranslation()
  const applicantType = applicant.isPrimaryApplicant ? EApplicantType.Applicant : EApplicantType.Coapplicant
  const waitingTime = 60000

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [sendLinkError, setSendLinkError] = useState(false)

  const [
    createBankAccountRequest,
    isCreatingBankAccountRequest,
    isCreatingBankAccountRequestError,
    isCreatingBankAccountRequestSuccess,
  ] = useCreateBankAccountRequestAndSendUrl()
  const [skipBankAccount, isSkippingBankAccount, isSkippingBankAccountError, isSkippingBankAccountSuccess] =
    useSkipBankAccountRequest()

  const handleTabChange = () => {
    setActiveTabIndex((prev) => (prev === 0 ? 1 : 0))
  }

  const onCreateBankRequestAndSendUrl = () => {
    const bankRequestDto = {
      applicantType,
      creditApplicationId,
      creditApplicationParams: {
        creditApplicationId,
        financingProgramId,
      },
    } as BankAccountRequestDto
    createBankAccountRequest(bankRequestDto)
      .then(() => setSendLinkError(false))
      .catch(() => setSendLinkError(true))
  }

  const onSkipBankAccount = async () => {
    const bankRequestDto = {
      applicantType,
      creditApplicationId,
      creditApplicationParams: {
        creditApplicationId,
        financingProgramId,
      },
    } as BankAccountRequestDto
    await skipBankAccount(bankRequestDto)
    setSendLinkError(false)
    setActiveTabIndex(1)
  }

  return (
    <Stack justifyContent="center" height="28vh">
      {!sendLinkError && canEdit && canSkipFlinks && (
        <Tabs variant="fullWidth" value={activeTabIndex} onChange={handleTabChange} aria-label="">
          <Tab icon="" iconPosition="end" label="Flinks" />
          <Tab icon="" iconPosition="end" label="Manual" />
        </Tabs>
      )}
      {/* Flinks tab */}
      {canEdit && (
        <TabPanel value={activeTabIndex} index={0}>
          <Stack justifyContent="center" height="15vh">
            {!applicant.incomeVerification?.requiredExternalStep && !sendLinkError ? (
              <AsyncActionButton
                variant="contained"
                color="primary"
                isPending={isCreatingBankAccountRequest}
                isError={isCreatingBankAccountRequestError}
                isSuccess={isCreatingBankAccountRequestSuccess}
                onClick={onCreateBankRequestAndSendUrl}
              >
                {t('incomeVerification.startVerification.continue')}
              </AsyncActionButton>
            ) : (
              <>
                {!!applicant.incomeVerification?.requiredExternalStep && !sendLinkError && (
                  <p>{t('incomeVerification.startVerification.waiting')}</p>
                )}
                {sendLinkError && <p>{t('incomeVerification.startVerification.error')}</p>}
                <AsyncActionButton
                  variant="contained"
                  color="primary"
                  isPending={isCreatingBankAccountRequest}
                  isError={isCreatingBankAccountRequestError}
                  isSuccess={isCreatingBankAccountRequestSuccess}
                  onClick={onCreateBankRequestAndSendUrl}
                  successWaitingTime={waitingTime}
                >
                  {t('incomeVerification.startVerification.resendLink')}
                </AsyncActionButton>
              </>
            )}

            {sendLinkError && (
              <div style={{ marginTop: '1rem' }}>
                {hasIncomeDocumentsPendingUpload && <p>{t('incomeVerification.startVerification.documentRequired')}</p>}
                {hasIncomeDocumentsPendingReview && <p>{t('incomeVerification.startVerification.reviewing')}</p>}
                <AsyncActionButton
                  variant="contained"
                  color="primary"
                  isPending={isSkippingBankAccount}
                  isError={isSkippingBankAccountError}
                  isSuccess={isSkippingBankAccountSuccess}
                  disabled={hasIncomeDocumentsPendingUpload || hasIncomeDocumentsPendingReview}
                  onClick={onSkipBankAccount}
                >
                  {t('incomeVerification.startVerification.sendManually')}
                </AsyncActionButton>
              </div>
            )}
          </Stack>
        </TabPanel>
      )}
      {/* Manual tab */}
      {canSkipFlinks && canEdit && (
        <TabPanel value={activeTabIndex} index={1}>
          <Stack justifyContent="center" height="15vh">
            {hasIncomeDocumentsPendingUpload && <p>{t('incomeVerification.startVerification.documentRequired')}</p>}
            {!hasIncomeDocumentsPendingUpload && !hasIncomeDocumentsPendingReview && !hasIncomeDocumentsApproved && (
              <>
                <p>{t('incomeVerification.startVerification.uploadMessage')}</p>
                <AsyncActionButton
                  variant="contained"
                  color="primary"
                  isPending={isSkippingBankAccount}
                  isError={isSkippingBankAccountError}
                  isSuccess={isSkippingBankAccountSuccess}
                  disabled={hasIncomeDocumentsPendingUpload || hasIncomeDocumentsPendingReview}
                  onClick={onSkipBankAccount}
                >
                  {t('incomeVerification.startVerification.sendManually')}
                </AsyncActionButton>
              </>
            )}
          </Stack>
        </TabPanel>
      )}
      {(hasIncomeDocumentsPendingReview || hasIncomeDocumentsApproved || !canEdit) &&
        !applicant.incomeVerification?.allIncomeTasksConfirmed && (
          <>
            <p>{t('incomeVerification.startVerification.reviewing')}</p>
            <p>{t('incomeVerification.startVerification.notified')}</p>
          </>
        )}
    </Stack>
  )
}

export default StartIncomeVerification
