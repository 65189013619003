import { BlockBlobClient } from '@azure/storage-blob'
import { MutationFunction, UseMutateAsyncFunction, useMutation } from '@tanstack/react-query'
import { EApplicantType, EFinancingProgram } from '@src/types/Constants'
import download from 'downloadjs'
import { getTimeStamp } from '@src/data/creditapp-selectors'
import { getApiClient } from '../api-client'
import { CreditApplicationParams, UploadFilesResultDto, UploadFilesDto } from '../type'

const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder()
  return textEncoder.encode(str).buffer
}

function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new Error('Invalid or missing file.'))
    }

    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result

      if (arrayBuffer === null) {
        resolve(null)
        return
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer))
        return
      }
      if (!arrayBuffer) {
        reject(new Error('Failed to read file into ArrayBuffer.'))
        return
      }

      resolve(arrayBuffer)
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}

interface SASTokenUrlGeneration {
  applicantType: EApplicantType
  status: string
  subKey: string
  typeId: number
  fileSASTokenUrls: Record<string, string>
  receivedOn: Date
}

const getSASTokenUrl = async (creditApplicationParams: CreditApplicationParams, sasToken: SASTokenUrlGeneration) => {
  const apiClient = getApiClient()

  const response = await apiClient.post<SASTokenUrlGeneration>(
    `/Document/${creditApplicationParams.financingProgramId}/${creditApplicationParams.creditApplicationId}/GenerateSasTokenUrl`,
    sasToken,
  )

  return response.data
}

export const uploadRequiredDocument: MutationFunction<UploadFilesResultDto, UploadFilesDto> = async (
  dto: UploadFilesDto,
) => {
  const sasDto = {
    applicantType: dto.applicantType,
    subKey: dto.subKey,
    typeId: dto.typeId,
    fileSASTokenUrls: {},
  } as SASTokenUrlGeneration
  dto.files.forEach((file, index) => {
    sasDto.fileSASTokenUrls[`${index}-${file.name}`] = ''
  })

  const sasTokens = await getSASTokenUrl(
    { creditApplicationId: dto.creditApplicationId, financingProgramId: dto.financingProgramId },
    sasDto,
  )

  await Promise.all(
    dto.files.map(async (file, index) => {
      const fileArrayBuffer = await convertFileToArrayBuffer(file)
      if (fileArrayBuffer === null || fileArrayBuffer.byteLength < 1) {
        return
      }
      const concernedSASUrl = sasTokens.fileSASTokenUrls[`${index}-${file.name}`]
      const blockBlobClient = new BlockBlobClient(concernedSASUrl)
      await blockBlobClient.uploadData(fileArrayBuffer)
    }),
  )

  return {
    creditApplicationId: dto.creditApplicationId,
    applicantType: dto.applicantType,
    typeId: dto.typeId,
    subKey: dto.subKey,
    status: sasTokens.status,
    receivedOn: sasTokens.receivedOn,
  } as UploadFilesResultDto
}

const GetTemplateContractPdfById: MutationFunction<
  void,
  { financingProgramId: EFinancingProgram; creditApplicationId: string; cvtNumber: string }
> = async ({ financingProgramId, creditApplicationId, cvtNumber }) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Blob>(
    `/Contract/${financingProgramId}/${creditApplicationId}/contractTemplate`,
    {
      responseType: 'blob',
    },
  )
  download(response.data, `cvt_${cvtNumber}_${getTimeStamp()}`)
}

export function useDownloadTemplateContractPdf(
  financingProgramId: EFinancingProgram,
  creditApplicationId: string,
): [UseMutateAsyncFunction<void, Error, string, unknown>, boolean, boolean, boolean] {
  const { mutateAsync, isPending, isError, isSuccess } = useMutation<void, Error, string>({
    mutationFn: (cvtNumber: string) =>
      GetTemplateContractPdfById({ financingProgramId, creditApplicationId, cvtNumber }),
  })

  return [mutateAsync, isPending, isError, isSuccess]
}

const GetSignedContractPdfById: MutationFunction<
  void,
  { financingProgramId: EFinancingProgram; creditApplicationId: string; cvtNumber: string }
> = async ({ financingProgramId, creditApplicationId, cvtNumber }) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Blob>(`/Contract/${financingProgramId}/${creditApplicationId}/contract`, {
    responseType: 'blob',
  })
  download(response.data, `cvt_${cvtNumber}`)
}

export function useDownloadSignedContractPdf(
  financingProgramId: EFinancingProgram,
  creditApplicationId: string,
): [UseMutateAsyncFunction<void, Error, string, unknown>, boolean, boolean, boolean] {
  const { mutateAsync, isPending, isError, isSuccess } = useMutation<void, Error, string>({
    mutationFn: (cvtNumber: string) => GetSignedContractPdfById({ financingProgramId, creditApplicationId, cvtNumber }),
  })

  return [mutateAsync, isPending, isError, isSuccess]
}
