import * as yup from 'yup'

export const EditProfileInformationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  jobTitle: yup.string().required(),
  phone: yup.string().notRequired(),
  language: yup.string().required().oneOf(['fr', 'en']),
})

export type EditProfileInformation = yup.InferType<typeof EditProfileInformationSchema>
