import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { ProductsComputeRequestDto } from '@src/types/ProductsComputeRequestDto'
import { ProductsComputeResultDto } from '@src/types/ProductsComputeResultDto'
import { EFinancingProgram } from '@src/types/Constants'
import { getApiClient } from '../api-client'

const SCOPE = 'calculator-rate'
const CALCULATE = 'calculate'

const keysFactory = {
  computeProductsLoan: (calculator: ProductsComputeRequestDto | undefined) =>
    [{ scope: SCOPE, entity: CALCULATE, calculator }] as const,
}

const computeProductsLoan = async (
  _ctx: QueryFunctionContext<ReturnType<(typeof keysFactory)['computeProductsLoan']>>,
  calculator: ProductsComputeRequestDto | undefined,
) => {
  const apiClient = getApiClient()

  const response = await apiClient.post<ProductsComputeResultDto>(
    `/${EFinancingProgram.Products}/Calculator/Compute`,
    calculator,
  )

  return response.data
}

const useComputeProductsLoan = (
  dto: ProductsComputeRequestDto | undefined,
): [ProductsComputeResultDto | null, boolean] => {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.computeProductsLoan(dto),
    queryFn: (ctx) => computeProductsLoan(ctx, dto),
    enabled: !!dto,
  })

  return [data ?? null, isFetching]
}

export default useComputeProductsLoan
