import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  Brightness4 as Brightness4Icon,
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  Translate as TranslateIcon,
  MonetizationOn as MonetizationOnIcon,
  Calculate as CalculatorIcon,
} from '@mui/icons-material'
import { Trans, useTranslation } from 'react-i18next'
import { CLEAR_CACHE_EXCEPTION_KEY, EFinancingProgram, EThemeMode, HOME_PAGE } from '@src/types/Constants'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { useColorMode } from '@src/contexts/ThemeContext'
import MenuIcon from '@mui/icons-material/Menu'
import { useMemo, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useUser } from '@src/contexts/AppContext'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { AddApplicationButton, AddApplicationListItem } from '@src/components/AddApplication'
import { merge } from 'lodash-es'
import { useUserSupportsProgram } from '@src/containers/ViewCreditApplicationPage/credit-hooks'
import EditProfileDialog from './EditProfileDialog'

const clearCache = () => {
  const persistentValues: Record<string, string> = {}
  CLEAR_CACHE_EXCEPTION_KEY.forEach((key) => {
    const cachedValue = localStorage.getItem(key)
    if (cachedValue) persistentValues[key] = cachedValue
  }, {})
  localStorage.clear()
  Object.entries(persistentValues).forEach(([key, value]) => {
    localStorage.setItem(key, value)
  })
}

const stringAvatar = (name: string) => {
  return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
}

const innerTheme = (theme: Theme) =>
  createTheme(
    merge(theme, {
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'text',
            color: 'inherit',
          },
        },
        MuiIconButton: {
          defaultProps: {
            color: 'inherit',
          },
        },
      },
    }),
  )

const AppHeader = (): JSX.Element => {
  const { palette, logoContrast, logoAlt, phone } = useTheme()
  const { toggleColorMode } = useColorMode()
  const { t, i18n } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { instance } = useMsal()
  const account = useAccount()
  const location = useLocation()
  const user = useUser()
  const [merchant] = useMerchantById(user?.merchantId ?? '')
  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null)
  const [openEditProfile, setOpenEditProfile] = useState(false)
  const navigate = useNavigate()
  const supportsProducts = useUserSupportsProgram(EFinancingProgram.Products)

  const hrefPhone = useMemo(() => `tel:${phone.replaceAll(/[- ()]/g, '')}`, [phone])

  const handleMenuOpenProfile = () => {
    setAnchorUserEl(null)
    setOpenEditProfile(true)
  }

  const handleDrawerOpenProfile = () => {
    setOpenDrawer(false)
    setOpenEditProfile(true)
  }

  const openUserMenu = Boolean(anchorUserEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorUserEl(null)
  }

  const handleLogout = async () => {
    clearCache()
    if (account) await instance.logoutRedirect({ account })
    else navigate(HOME_PAGE)
  }

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.resolvedLanguage === 'fr' ? 'en' : 'fr').catch(reportErrorToConsole)
  }

  return (
    <>
      <AppBar
        position="static"
        color="secondary"
        enableColorOnDark
        sx={{
          displayPrint: 'none',
          backgroundImage: 'none',
        }}
      >
        <ThemeProvider theme={innerTheme}>
          <Toolbar>
            <IconButton sx={{ display: { lg: 'none' } }} size="large" onClick={() => setOpenDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Button className="iFinanceBanner" href={HOME_PAGE} sx={{ flexShrink: 0, mr: 2 }}>
              <img src={logoContrast} alt={logoAlt} style={{ height: '30px' }} />
            </Button>

            <Stack direction="row" spacing={1} sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
              {/* Navigation */}
              <Button startIcon={<MonetizationOnIcon />} href="/credits">
                {t('menus.application')}
              </Button>
              <AddApplicationButton supportedFinancingPrograms={merchant?.supportedFinancingPrograms ?? []} />
              {supportsProducts && (
                <Button startIcon={<CalculatorIcon />} href="/calculator">
                  {t('menus.calculator')}
                </Button>
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              {/* Actions */}
              <Typography
                variant="caption"
                sx={{ display: { xs: 'none', lg: 'block' }, maxWidth: '300px', textAlign: 'end', mr: 1 }}
              >
                <Trans
                  i18nKey="common.contactUs"
                  values={{ phone }}
                  components={{ a: <Link href={hrefPhone} style={{ textWrap: 'nowrap' }} /> }}
                />
              </Typography>

              <Tooltip title={t('common.targetLanguageLong')}>
                <Button onClick={changeLanguage}>{t('common.targetLanguage')}</Button>
              </Tooltip>
              <Tooltip title={palette.mode === EThemeMode.Dark ? t('common.lightMode') : t('common.darkMode')}>
                <IconButton onClick={() => toggleColorMode()}>
                  {palette.mode === EThemeMode.Dark ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
              </Tooltip>

              <Tooltip title={t('menus.account')}>
                <IconButton
                  aria-controls={openUserMenu ? 'user-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openUserMenu ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
              <Menu id="user-menu" anchorEl={anchorUserEl} open={openUserMenu} onClose={handleClose}>
                <MenuItem onClick={handleMenuOpenProfile}>{t('menus.profile')}</MenuItem>
                <MenuItem onClick={handleLogout} sx={{ color: 'error.main' }}>
                  {t('menus.logout')}
                </MenuItem>
              </Menu>
            </Stack>
          </Toolbar>
        </ThemeProvider>
      </AppBar>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ sx: { width: '280px', maxWidth: '90%' } }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack spacing={1} sx={{ p: 2 }}>
            <Avatar>{stringAvatar(user!.fullName)}</Avatar>
            <Typography variant="subtitle1">{user!.fullName}</Typography>
          </Stack>
          <Divider />
          <List>
            {/* Navigation */}
            <ListItemButton onClick={handleDrawerOpenProfile}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('menus.profile')} />
            </ListItemButton>
            <ListItemButton selected={location.pathname === '/credits'} href="/credits">
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary={t('menus.application')} />
            </ListItemButton>
            {supportsProducts && (
              <ListItemButton selected={location.pathname === '/calculator'} href="/calculator">
                <ListItemIcon>
                  <CalculatorIcon />
                </ListItemIcon>
                <ListItemText primary={t('menus.calculator')} />
              </ListItemButton>
            )}
            <AddApplicationListItem supportedFinancingPrograms={merchant?.supportedFinancingPrograms ?? []} />
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="caption" sx={{ p: 1 }}>
            <Trans
              i18nKey="common.contactUs"
              values={{ phone }}
              components={{ a: <Link href={hrefPhone} style={{ textWrap: 'nowrap' }} /> }}
            />
          </Typography>
          <Divider />
          <List>
            {/* Actions */}
            <ListItemButton onClick={() => toggleColorMode()}>
              <ListItemIcon>
                {palette.mode === EThemeMode.Dark ? <Brightness7Icon /> : <Brightness4Icon />}
              </ListItemIcon>
              <ListItemText primary={palette.mode === EThemeMode.Dark ? t('common.lightMode') : t('common.darkMode')} />
            </ListItemButton>
            <ListItemButton onClick={changeLanguage}>
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.targetLanguageLong')} />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={handleLogout} sx={{ color: 'error.main' }}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: 'error.main' }} />
              </ListItemIcon>
              <ListItemText primary={t('menus.logout')} />
            </ListItemButton>
          </List>
        </Stack>
      </Drawer>
      <EditProfileDialog open={openEditProfile} handleClose={() => setOpenEditProfile(false)} />
    </>
  )
}

export default AppHeader
