import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
} from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { DatePickerInput, SimpleDatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/types/Merchant'
import { memo, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUser } from '@src/contexts/AppContext'
import { EMerchantType, EOriginSystemId } from '@src/types/Constants'
import DialogCloseButton from '@src/components/common/DialogCloseButton'
import { CreditDashboardFilters } from '@src/data/api/type'
import { addDays, addYears } from 'date-fns'
import { useCreditApplicationStatuses, useCurrentCustomerSteps } from './creditEntriesFiltersDialog-hooks'

type Props = {
  filterValues: CreditDashboardFilters
  open: boolean
  merchant: Merchant | null
  onApplyFilters: (filters: CreditDashboardFilters) => void
  onCancel: () => void
  handleClearFilter: () => void
}

const CreditEntriesFiltersDialog = ({
  filterValues,
  open,
  merchant,
  onApplyFilters,
  onCancel,
  handleClearFilter,
}: Props) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<CreditDashboardFilters>({
    defaultValues: filterValues,
  })

  const [merchantsList, isGettingMerchants] = useMerchantByParentId(merchant?.id ?? '')
  const user = useUser()
  const creditApplicationStatuses = useCreditApplicationStatuses()
  const currentCustomerSteps = useCurrentCustomerSteps()

  const selectedMerchantId = watch('merchantId')
  const endDate = watch('endDate')
  const minDate = useMemo(() => {
    return addYears(addDays(endDate, 2), -2)
  }, [endDate])

  useEffect(() => {
    if (!open) {
      reset(filterValues)
    }
  }, [open, filterValues, reset])

  return (
    <Dialog
      component="form"
      onSubmit={handleSubmit(onApplyFilters)}
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('common.filters')}
        <DialogCloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent>
        {isGettingMerchants ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Stack spacing={2}>
            <Autocomplete
              id="merchantList"
              options={merchantsList}
              value={merchantsList.find((m) => m.id === selectedMerchantId) ?? null}
              onChange={(_event, selectedMerchant: Merchant | null) => {
                if (selectedMerchant === null) setValue('merchantId', '')
                else setValue('merchantId', selectedMerchant?.id)
              }}
              disabled={merchant?.merchantTypeId !== EMerchantType.Banner}
              autoHighlight
              getOptionLabel={(option) => option.name}
              noOptionsText={t('common.searchYieldsNoResults')}
              renderInput={(merchantList) => (
                <InputTextField
                  placeholder={t('common.all')}
                  {...merchantList}
                  label={t('common.merchant')}
                  inputProps={{
                    ...merchantList.inputProps,
                  }}
                />
              )}
            />
            <SelectComponent
              valueList={creditApplicationStatuses}
              label={t('common.status') as string}
              {...register('creditApplicationStatus')}
            />
            <SelectComponent
              valueList={currentCustomerSteps}
              label={t('common.state') as string}
              {...register('currentCustomerStep')}
            />

            <Stack justifyContent="space-between" direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
              <Grid item>
                <DatePickerInput
                  name="startDate"
                  control={control}
                  label={t('common.startDate')}
                  minDate={minDate}
                  maxDate={new Date()}
                  error={errors?.startDate}
                />
              </Grid>
              <Grid item>
                <SimpleDatePicker label={t('common.endDate')} name="endDate" control={control} />
              </Grid>
            </Stack>

            <Stack>
              <FormControlLabel
                label={t('common.requiresMerchantAttention')}
                control={
                  <Checkbox
                    checked={watch('requiresMerchantAttention')}
                    {...register('requiresMerchantAttention')}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
              <FormControlLabel
                label={t('common.editableApplications')}
                control={
                  <Checkbox
                    checked={watch('originSystemId') !== ''}
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      if (isChecked) {
                        setValue('originSystemId', EOriginSystemId.MerchantDashboard)
                      } else {
                        setValue('originSystemId', '')
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
              <FormControlLabel
                label={t('common.currentUserApplications')}
                control={
                  <Checkbox
                    checked={watch('createdByUserId') !== ''}
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      if (isChecked && user) {
                        setValue('createdByUserId', user.id)
                      } else {
                        setValue('createdByUserId', '')
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <AsyncActionButton color="error" startIcon={<CancelIcon />} onClick={handleClearFilter}>
          {t('common.reset')}
        </AsyncActionButton>
        <AsyncActionButton variant="contained" color="primary" type="submit" startIcon={<DoneIcon />}>
          {t('common.apply')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default memo(CreditEntriesFiltersDialog)
