import { CreditApplication, DraftCreditApplicationSchema } from '@src/types/CreditApplicationSchema'
import { useState } from 'react'
import { useMerchantById } from '@src/data/api/merchants-api/merchants-api'
import { useUser } from '@src/contexts/AppContext'
import { useSaveCreditApplicationDraft } from '@src/data/api/credit-api/credit-api'
import { useNavigate, useParams } from 'react-router-dom'
import { EBeneficiaryType, ECreditApplicationStatus, EFinancingProgram } from '@src/types/Constants'
import CreditApplicationForm from './components/CreditApplicationForm'

const CreateCreditApplicationPage = (): JSX.Element => {
  const user = useUser()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()

  const [merchant] = useMerchantById(user?.merchantId ?? '')
  const [creditApplication] = useState<CreditApplication>({
    ...(DraftCreditApplicationSchema.getDefault() as unknown as CreditApplication),
  })
  const navigate = useNavigate()
  const [saveCreditAppMutation, isPending, isError, reset, error] = useSaveCreditApplicationDraft()

  const onSave = async (data: Partial<CreditApplication>) => {
    if (!data.beneficiaryTypeId) data.beneficiaryTypeId = EBeneficiaryType.Applicant
    await saveCreditAppMutation(data).then((creditApp) => {
      reset()
      if (creditApp.status === ECreditApplicationStatus.Draft) navigate(`/`)
      else {
        navigate(`/credits/${creditApp.financingProgramId}/${creditApp.id}`)
      }
    })
  }

  return (
    <CreditApplicationForm
      merchant={merchant}
      creditApplication={creditApplication}
      onSave={onSave}
      isPending={isPending}
      isError={isError}
      serverErrors={error}
      financingProgramId={financingProgramId!}
    />
  )
}

export default CreateCreditApplicationPage
