import PageSpinner from '@src/components/PageSpinner'
import { useUser } from '@src/contexts/AppContext'
import { useCreditApplicationById } from '@src/data/api/credit-api/credit-api'
import { useMerchantById, useMerchantByParentId } from '@src/data/api/merchants-api/merchants-api'
import { useGetHolidaysForYear } from '@src/data/api/worksheet-api/worksheet-api'
import {} from '@src/types/CreditApplicationSchema'
import { useParams } from 'react-router-dom'
import { buildEditPersonalLoanWorksheetDtoSchema } from '@src/types/WorksheetSchema'
import { EFinancingProgram } from '@src/types/Constants'
import { useMemo } from 'react'
import { getMerchantList, usePaymentPlanList } from '../ViewCreditApplicationPage/worksheet-hooks'
import EditWorksheet from './components/EditWorksheet'

const CreateWorksheetPage = () => {
  const { financingProgramId, id } = useParams<{ financingProgramId: EFinancingProgram; id: string }>()

  const user = useUser()
  const [creditApplication, isLoadingCreditApp] = useCreditApplicationById(
    { creditApplicationId: id!, financingProgramId: financingProgramId! },
    false,
  ) // if true, poll every 5 secs
  const [merchant, isLoadingMerchant] = useMerchantById(creditApplication?.merchantId ?? '')
  const [merchantsListFromParentId, isFetchingMerchantsList] = useMerchantByParentId(user!.merchantId)
  const { merchantList, setMerchantList } = getMerchantList(
    creditApplication,
    merchantsListFromParentId,
    merchant!,
    user!,
  )
  const [listHolidays, isLoadingHolidays] = useGetHolidaysForYear(new Date().getFullYear())
  const merchantPayments = usePaymentPlanList(creditApplication, merchant!)
  const isLoadingInfo = isLoadingCreditApp || isLoadingMerchant || isFetchingMerchantsList || isLoadingHolidays

  const validator = useMemo(() => {
    if (
      creditApplication.applicant.hardHitReportReceivedOn &&
      creditApplication.finalCreditDecision.maxAmountFinanced &&
      creditApplication.requestedLoanAmount
    )
      return buildEditPersonalLoanWorksheetDtoSchema(
        creditApplication.applicant.hardHitReportReceivedOn,
        creditApplication.finalCreditDecision.maxAmountFinanced,
        creditApplication.requestedLoanAmount,
        listHolidays,
      )
    return null
  }, [
    creditApplication.applicant.hardHitReportReceivedOn,
    creditApplication.finalCreditDecision.maxAmountFinanced,
    creditApplication.requestedLoanAmount,
    listHolidays,
  ])

  return (
    <>
      <PageSpinner withBackdrop isLoading={isLoadingInfo} />

      {!isLoadingInfo && creditApplication && merchant && validator && (
        <EditWorksheet
          listHolidays={listHolidays}
          merchant={merchant}
          creditApplication={creditApplication}
          user={user!}
          merchantsListFromParentId={merchantsListFromParentId}
          merchantPayments={merchantPayments}
          merchantList={merchantList}
          setMerchantList={setMerchantList}
          validator={validator}
        />
      )}
    </>
  )
}

export default CreateWorksheetPage
