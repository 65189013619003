import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import initializeAppInsights from './services/AppInsights'
import { initializeMsal } from './services/Authentification'
import { reportErrorToConsole, setupTracking } from './services/error-logger'
import App from './containers/App/App'
import { initializeI18N } from './services/i18n'

const ai = initializeAppInsights()
setupTracking(ai)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)
const msalInstance = initializeMsal()

Promise.all([initializeI18N(), msalInstance.initialize()])
  .then(() => {
    root.render(
      <Suspense fallback="loading">
        <I18nextProvider i18n={i18n}>
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
            </QueryClientProvider>
          </MsalProvider>
        </I18nextProvider>
      </Suspense>,
    )
  })
  .catch(reportErrorToConsole)
