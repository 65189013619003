import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material'
import ActionsDialog from '@src/components/ActionsDialog'
import PageSpinner from '@src/components/PageSpinner'
import { useDeleteCoapplicant } from '@src/data/api/credit-api/credit-api'
import { formatPhone } from '@src/services/Formatter'
import { ApplicantDto, CreditApplication } from '@src/types/CreditApplicationSchema'
import { MouseEventHandler, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EApplicantType, EFinancingProgram, ELanguage } from '@src/types/Constants'

type Props = {
  applicant: ApplicantDto
  applicantTitle: string
  applicantType: EApplicantType
  creditApplication: CreditApplication
  disabled: boolean
  financingProgramId: EFinancingProgram
}

const ApplicantInfoBox = ({
  applicant,
  applicantTitle,
  applicantType,
  creditApplication,
  disabled,
  financingProgramId,
}: Props) => {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState<HTMLButtonElement | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const navigate = useNavigate()
  const [deleteCoapplicant, isDeleting] = useDeleteCoapplicant()

  const applicantFullname = `${applicant.firstName} ${applicant.lastName}`
  const applicantInfoBoxTitle = `${applicantTitle}`
  const phone = formatPhone(applicant.cellPhone)
  const preferredLanguage = () => {
    if (applicant.languageId === ELanguage.English) {
      return t('common.english')
    }
    return t('common.french')
  }

  const handleEditApplicant = useCallback(() => {
    const editingIsApplicant = applicantType === EApplicantType.Applicant
    navigate(`/credits/${financingProgramId}/${creditApplication.id}/edit?showTabIndex=${editingIsApplicant ? 0 : 1}`)
  }, [applicantType, creditApplication, financingProgramId, navigate])

  const handleOpenMenu = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    setIsMenuOpen(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(null)
  }, [])

  const handleConfirmDialog = useCallback(async () => {
    setIsDialogOpen(false)
    await deleteCoapplicant({ creditApplicationId: creditApplication.id, financingProgramId })
  }, [creditApplication, deleteCoapplicant, financingProgramId])

  return (
    <Paper
      component={Grid}
      item
      xs={3.85}
      sx={{
        borderRadius: 2,
        p: 1,
        m: 1,
      }}
    >
      <PageSpinner isLoading={isDeleting} withBackdrop />

      <Stack direction="column" justifyContent="space-between" height="100%" spacing={1}>
        <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
          <span>{applicantInfoBoxTitle}:</span>
          {!disabled && (
            <>
              <IconButton style={{ marginLeft: 'auto' }} aria-label="close" onClick={handleOpenMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="dropdown-menu" anchorEl={isMenuOpen} open={Boolean(isMenuOpen)} onClose={handleCloseMenu}>
                <MenuItem onClick={handleEditApplicant}>{t('common.edit')}</MenuItem>
                {applicantType === EApplicantType.Coapplicant && (
                  <MenuItem
                    onClick={() => {
                      navigate(`/credits/${creditApplication.id}/coapplicant`)
                    }}
                  >
                    {t('common.replace')}
                  </MenuItem>
                )}
                {applicantType === EApplicantType.Coapplicant && (
                  <MenuItem
                    onClick={() => {
                      setIsMenuOpen(null)
                      setIsDialogOpen(true)
                    }}
                  >
                    {t('common.delete')}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </Typography>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {t('viewCreditApplication.name')}
            </Typography>
            <Grid item sx={{ mb: 3 }}>
              <Typography variant="body1">{applicantFullname}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ pl: 2 }}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {t('viewCreditApplication.phone')}
            </Typography>
            <Grid item>
              <Typography variant="body1"> {phone}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {t('viewCreditApplication.email')}
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body1"> {applicant.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ pl: 2 }}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              {t('viewCreditApplication.preferredLanguage')}
            </Typography>
            <Grid item>
              <Typography variant="body1"> {preferredLanguage()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <ActionsDialog
        open={isDialogOpen}
        onCancel={() => {
          setIsDialogOpen(false)
        }}
        onConfirm={handleConfirmDialog}
        title={t('viewCreditApplication.deleteCoapplican')}
      >
        <Typography variant="h5">{t('viewCreditApplication.confirmDeletionMessage')}</Typography>
      </ActionsDialog>
    </Paper>
  )
}
export default ApplicantInfoBox
