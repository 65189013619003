import { ButtonProps } from '@mui/material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { EFinancingProgram } from '@src/types/Constants'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

interface Props extends ButtonProps {
  id: string
  financingProgramId: EFinancingProgram
}

const FundingSummaryButton = ({ id, financingProgramId }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AsyncActionButton
      fullWidth
      variant="contained"
      onClick={() => navigate(`/credits/${financingProgramId}/${id}/funding-summary`)}
    >
      {t('viewCreditApplication.printFundingSummary')}
    </AsyncActionButton>
  )
}

export default FundingSummaryButton
