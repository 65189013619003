import { PublicClientApplication } from '@azure/msal-browser'
import { getApiClient, JWT_LOCAL_STORAGE_KEY, loadUserFromJwtString } from '@src/data/api/api-client'
import { msalConfig } from '../authConfig'
import { type JwtToken } from '../data/api/user-api/user-api'
import { UserDtoFromJwt } from '../types/User'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

export async function signIn(data: JwtToken): Promise<JwtToken> {
  const apiClient = getApiClient()
  return apiClient.post('/Login/GenerateJwtToken', data).then((response) => {
    return response.data as JwtToken
  })
}

export function saveUserToken(jwtToken: string): void {
  localStorage.setItem(JWT_LOCAL_STORAGE_KEY, jwtToken)
}

export function initializeMsal(): PublicClientApplication {
  return msalInstance
}

export function getUser(): UserDtoFromJwt | null {
  const token = localStorage.getItem(JWT_LOCAL_STORAGE_KEY)
  return token ? loadUserFromJwtString(token) : null
}
