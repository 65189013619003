import React, { useState } from 'react'
import { Stack, Typography, Button, CircularProgress, Box, Card, CardContent } from '@mui/material'
import InputTextField from '@src/components/InputTextField'
import { useTranslation } from 'react-i18next'
import { CalculatorSchemaType, CalculatorSchema } from '@src/types/calculatorSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { EFrequencyType, EProvince, ETermsList } from '@src/types/Constants'
import useComputeProductsLoan from '@src/data/api/calculator-api/calculator-api'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import SelectComponent from '@src/components/SelectComponent'
import { ProductsComputeRequestDto } from '@src/types/ProductsComputeRequestDto'
import { Calculate as CalculatorIcon } from '@mui/icons-material'
import { FormatCurrency } from '@src/services/Formatter'

const ProductsCalculator = () => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CalculatorSchemaType>({
    mode: 'onBlur',
    resolver: yupResolver(CalculatorSchema),
  })

  const [calculatorData, setCalculatorData] = useState<ProductsComputeRequestDto | undefined>(undefined)

  const [calculatedRate, isFetching] = useComputeProductsLoan(calculatorData)

  const onSubmit = (data: CalculatorSchemaType) => {
    const calculationData = {
      province: data?.province ?? EProvince.nunavut,
      amountRequested: data.amountRequested !== undefined ? data.amountRequested : 0,
      frequency: data.frequency!,
      term: data.terms!,
    }

    setCalculatorData(calculationData)
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h1" gutterBottom>
          {t('calculator.title')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            {/* Uncomment when rates are dynamic by province
           <SelectComponent
            valueList={Object.values(EProvince).map((item) => {
              const data: SelectValueListItem = { label: `province.${item}`, value: item }
              return data
            })}
            label="Province"
            {...register('province')}
            error={errors?.province}
          /> */}

            <SelectComponent
              valueList={Object.values(EFrequencyType).map((item) => {
                const data: SelectValueListItem = { label: `calculator.frequency.${item}`, value: item }
                return data
              })}
              label={t('calculator.frequency.label') as string}
              {...register('frequency')}
              error={errors?.frequency}
            />

            <SelectComponent
              valueList={ETermsList.map((item: number) => {
                const data: SelectValueListItem = { label: `calculator.termMonths.${item}`, value: item }
                return data
              })}
              label={t('calculator.terms') as string}
              {...register('terms', { valueAsNumber: true })}
              error={errors?.terms}
            />

            <InputTextField
              label={t('calculator.amountRequested')}
              error={errors.amountRequested}
              {...register('amountRequested', { valueAsNumber: true })}
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={isFetching}
              endIcon={<CalculatorIcon />}
            >
              {isFetching ? <CircularProgress size={24} /> : t('calculator.calculate')}
            </Button>
          </Stack>

          {calculatedRate && (
            <Box mt={4} p={2} maxWidth={600} mx="auto">
              <Card elevation={3}>
                <CardContent>
                  {/* Main message - loan payment */}
                  <Typography variant="h6" gutterBottom>
                    {t('calculator.monthlyPayment')}
                  </Typography>
                  <Typography variant="h4" color="primary" gutterBottom>
                    {FormatCurrency(calculatedRate.payments)}
                  </Typography>

                  {/* Total interest costs */}
                  <Typography variant="body1" gutterBottom>
                    {t('calculator.totalInterestCosts', { rate: calculatedRate.rate })}
                  </Typography>

                  {/* Additional details */}
                  <Stack spacing={2} direction="row" mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      {t('calculator.annualFees', { annualFees: calculatedRate.annualFees })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('calculator.totaleCreditRate', { totalRate: calculatedRate.totalCreditRate })}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          )}
        </form>
      </CardContent>
    </Card>
  )
}

export default React.memo(ProductsCalculator)
