import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material'
import { CACHED_THEME_MODE, ColorModeContext, useCustomTheme } from '@src/contexts/ThemeContext'
import { EThemeMode, PREFERS_MODE_SETTING } from '@src/types/Constants'
import { useEffect, useMemo, useState } from 'react'

type Props = {
  children: JSX.Element
}

const favIconLink: HTMLLinkElement = document.querySelector("link[rel~='icon']")!

const ThemeProvider = ({ children }: Props) => {
  const [mode, setMode] = useState<EThemeMode>(CACHED_THEME_MODE)
  const customTheme = useCustomTheme(mode)
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newThemeName: EThemeMode = prevMode === EThemeMode.Light ? EThemeMode.Dark : EThemeMode.Light
          localStorage.setItem(PREFERS_MODE_SETTING, newThemeName)
          return newThemeName
        })
      },
    }),
    [],
  )

  useEffect(() => {
    if (customTheme?.favicon) favIconLink.href = customTheme.favicon
  }, [customTheme?.favicon])

  if (!customTheme) return null

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={customTheme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default ThemeProvider
