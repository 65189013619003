/* eslint-disable func-names */
import { boolean, InferType, mixed, object, string } from 'yup'
import { formatDate, convertToLocalDate } from '@src/services/Formatter'
import { isLastDayOfMonth, isWeekend } from 'date-fns'
import { Constants, EPaymentFrequency, EPaymentPlan, EWorksheetStatus, PaymentMethod } from './Constants'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'

const editWorksheetDtoSchema = {
  requestedLoanAmount: yupExtInt.double
    .required('common.errors.required')
    .positive()
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount'),
  loanTerm: yupExtInt.integer.default(12).min(12).nonNullable().required(),
  deliveryOn: string()
    .isValidDate()
    .default(new Date().toDateString())
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  firstPaymentOn: string()
    .isValidDate()
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  paymentFrequency: string().default('monthly').required('common.errors.required').typeError('common.errors.required'),
}

export const EditWorksheetDtoSchema = object(editWorksheetDtoSchema)

export type EditWorksheetDto = InferType<typeof EditWorksheetDtoSchema>

export const buildEditPersonalLoanWorksheetDtoSchema = (
  hardHitReportReceivedOn: Date,
  finalDecisonMaxAmountFinanced: number,
  requestedLoanAmount: number,
  listHolidays: Date[],
) => {
  const requestedAmount = Math.min(requestedLoanAmount, finalDecisonMaxAmountFinanced)
  return yup.default.object({
    status: yup.default.string().required().default(EWorksheetStatus.Draft),

    amountRequested: yupExtInt.double
      .required('common.errors.required')
      .positive()
      .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
      .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount')
      .max(finalDecisonMaxAmountFinanced, 'common.errors.lowerOrEqual_finalDecisonMaxAmountFinanced')
      .default(requestedAmount)
      .test('total-merchant-payment-test', 'common.errors.notEqualMerchantPayment', function (value) {
        const totalMerchantPayment = (this.parent as { merchantPayments: MerchantPayment[] }).merchantPayments.reduce(
          (acc, curr) => Number(acc) + Number(curr.amount),
          0,
        )
        return totalMerchantPayment === value
      }),

    deliveryOn: yup.default
      .string()
      .default(formatDate(new Date()).toString())
      .required('common.errors.required')
      .typeError('common.errors.required')
      .test('expiresOn-test', 'common.errors.deliveryOnLowerThanExpiresOn', function (value) {
        const deliveryDate = new Date(value)
        const expiredDate = new Date(hardHitReportReceivedOn)
        expiredDate.setDate(expiredDate.getDate() + 90)
        return deliveryDate <= expiredDate
      })
      .test('deliveryOn-test', 'common.errors.deliveryOnGreaterOrEqualThanToday', function (value) {
        const deliveryDate = new Date(value)
        deliveryDate.setUTCHours(0, 0, 0, 0)

        const now = new Date()
        now.setUTCHours(0, 0, 0, 0)

        return now <= deliveryDate
      })
      .test('deliveryOn-test', 'common.errors.deliveryOnIsHoliday', function (value) {
        const deliveryDate = convertToLocalDate(value)
        const isHoliday = listHolidays?.toString().includes(formatDate(deliveryDate))
        const isNotEndOfMonthAndWeekday = isWeekend(deliveryDate) && !isLastDayOfMonth(deliveryDate)

        return !isHoliday && !isNotEndOfMonthAndWeekday
      }),

    firstPaymentOn: yup.default.string().required('common.errors.required').typeError('common.errors.required'),

    includeInsurance: yup.default.bool().default(false),

    addFastPayments: yup.default.bool().default(false),

    paymentFrequency: yup.default.mixed<EPaymentFrequency>().default(EPaymentFrequency.Monthly).required(),

    term: yupExtInt.integer.min(6).nonNullable().required(),

    fundConfirmationNote: yup.default.string().default(null).nullable(),

    merchantPayments: mixed<MerchantPayment[]>().default([]),

    paymentPlanId: yup.default.mixed<EPaymentPlan>().required().default(EPaymentPlan.regularDailyInterests),

    id: yup.default.string().default(null).nullable(),

    creditApplicationId: yup.default.string().default(null).nullable(),

    versionTag: yup.default.string().default(''),
  })
}

export const EditPersonalLoanWorksheetDtoSchema = buildEditPersonalLoanWorksheetDtoSchema(
  new Date(Date.now() + 90 * 24 * 60 * 60 * 1000), // Adds 90 days in milliseconds to the current date
  Constants.MaximumLoanAmount,
  Constants.MinimumLoanAmount,
  [],
)
export type EditPersonalLoanWorksheetDto = InferType<typeof EditPersonalLoanWorksheetDtoSchema>
export type EditPersonalLoanWorksheetDtoDefaultValue = InferType<typeof EditPersonalLoanWorksheetDtoSchema>

export const merchantPaymentSchema = object({
  merchantId: string().required(),
  amount: yupExtInt.double.positive().required(),
  paymentMethod: string().default(PaymentMethod.bankTransfer).required().oneOf(Object.values(PaymentMethod)),
})

export type MerchantPayment = InferType<typeof merchantPaymentSchema>

export const computePersonalLoanDtoSchema = object({
  amountRequested: yupExtInt.double
    .required('AR')
    .positive()
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount'),
  paymentFrequency: yup.default.string().required('PF').nonNullable(),
  paymentPlanId: string().required('PPID'),
  deliveryOn: yup.default.string().default(new Date().toDateString()).nonNullable().required('DO'),
  firstPaymentOn: yup.default.date().test('deliveryOn-test', function (value?: Date) {
    const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
    const twoMonthsLater = new Date(deliveryOn)
    twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

    return !deliveryOn || !value || (value > new Date(deliveryOn) && value <= twoMonthsLater)
  }),
  term: yupExtInt.integer.min(6).nonNullable().required(),
})

export type ComputePersonalLoanDtoSchema = InferType<typeof computePersonalLoanDtoSchema>
export const computePossibleTermsDtoSchema = object({
  merchantId: string().required('common.errors.required'),
  paymentPlanId: string().required('common.errors.required'),
  interestRate: yupExtInt.double.positive().required('common.errors.required'),
  hasCoapplicant: boolean().required('common.errors.required'),
  stateIso: string().required('common.errors.required'),
  paymentFrequency: string().oneOf(Object.values(EPaymentFrequency)).required('common.errors.required'),
  deliveryOn: yup.default.string().default(new Date().toDateString()).nonNullable().required('DO'),
  firstPaymentOn: yup.default.date().test('deliveryOn-test', function (value?: Date) {
    const deliveryOn = (this.parent as { deliveryOn: string }).deliveryOn
    const twoMonthsLater = new Date(deliveryOn)
    twoMonthsLater.setMonth(twoMonthsLater.getMonth() + 2)

    return !deliveryOn || !value || (value > new Date(deliveryOn) && value <= twoMonthsLater)
  }),
  maxPmtAmount: yupExtInt.double.positive().required('common.errors.required'),
  amountRequested: yupExtInt.double
    .required('AR')
    .positive()
    .min(Constants.MinimumLoanAmount, 'common.errors.greaterOrEqual_MinimumLoanAmount')
    .max(Constants.MaximumLoanAmount, 'common.errors.lowerOrEqual_MaximumLoanAmount'),
  includeInsurance: boolean().required('common.errors.required'),
})

export type ComputePossibleTermsDto = InferType<typeof computePossibleTermsDtoSchema>
